import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { fetchTeamById } from "../../api";
import ContentSection from "../Common/ContentSection";

const TeamView = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTeamb = async () => {
      try {
        const response = await fetchTeamById(id);
        if (response) {
        setTeam(response);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamb();
  }, [id]);  

 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Image src={team?.imagePath} alt={team?.alt_text} borderRadius="md" mb={4} />
      <Heading as="h1" mb={4}>
        {team?.name}
      </Heading>     
      
           {team?.email && (
           <ContentSection title={"Email"} desc={Date(team?.email)} />
           )}
           {team?.designation && (
           <ContentSection title={"Designation"} desc={Date(team?.designation)} />
           )}           
           {team?.linkedIn && (
           <ContentSection title={"LinkedIn"} desc={team?.linkedIn} />
           )}           
      
    </Box>
  );
};

export default TeamView;