import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Flex,
  Card,
  CardBody,
  Image,
  Stack,
  Heading,
  Text,
  CardFooter,
  ButtonGroup,
  HStack,
  VStack,
  SimpleGrid,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { fetchTeams,deleteTeam,fetchTeamById,editTeam,createTeam } from "../../api";
import axios from "axios";
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from "react-redux";

const ClientManagement = () => {
  const [teams, setTeam] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",         
    designation: "",
    image:"",
    linkedIn:""
  });  
  const [editTeamId, setEditTeamId] = useState(null);
  const toast=useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 6;

  // Fetch Teams
  useEffect(() => {
    fetchTeamsb();
  }, []);

  
  
  const totalPages = Math.ceil(teams.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teams.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const fetchTeamsb = async () => {
    try {
      const response = await fetchTeams();
      setTeam(response);
      console.log(response);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };
  const { user } = useSelector((state) => state.auth);

  // Handle delete button click
  const handleDelete = async (teamId) => {
    try {
      await deleteTeam(teamId);
      setTeam(teams.filter((team) => team.id !== teamId));
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  };
  return (
    <Box p={5}>
<Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} mb={4} as={RouterLink} to="/add-team">
        Add Team
      </Button>
      
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={10}>
      {currentItems.map((team, index) => (
        <Card maxW="sm" key={index}>
          <CardBody padding={0}>
            <Image
              src={`${team.imagePath}`}
              borderRadius="lg"
              height={370}
              width={"100%"}
              borderBottomLeftRadius={0}
              borderBottomRightRadius={0}
            />
            <IconButton
          aria-label="Edit"
          icon={<EditIcon />}
          size="lg"
          position="absolute"
          top="15%"
          as={RouterLink} 
          to={`/edit-team/${team.id}`}
          left="93%"
          transform="translateY(-50%)"        
          bg={"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}
          color="white"
          borderRadius={"30px"}
          _hover={{ bg: "gray.600" }}
        />
        {user.roles.includes("superadmin") &&( 
            <IconButton
          aria-label="Delete"
          icon={<DeleteIcon />}
          size="lg"
          position="absolute"
          top="28%"
          left="93%"
          onClick={() => handleDelete(team.id)}
          transform="translateY(-50%)"        
          colorScheme="red"
          color="white"
          borderRadius={"30px"}
          _hover={{ bg: "gray.600" }}
        />
        )}
          </CardBody>
          <CardFooter textAlign="center" justifyContent="center">
            <VStack>
              <Heading size="md">{team.name}</Heading>
              <Text>{team.designation}</Text>
            </VStack>
          </CardFooter>
        </Card>
      ))}
    </SimpleGrid>
          
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </Box>
  );
};

export default ClientManagement;