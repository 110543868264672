import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { createModule, deleteModule, fetchModules, updateModule } from "../../api";
import { useSelector } from "react-redux";

const ModuleManagement = () => {
  const [modules, setModules] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    routes: [{ path: "", method: "GET", description: "" }],
    rolesAllowed: [],
  });
  const [roles, setRoles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [moduleToUpdate, setModuleToUpdate] = useState(null); // State for the module to update
  const toast=useToast();
  // Fetch modules
  const { user } = useSelector((state) => state.auth);
  const fetchModulesb = async () => {
    try {
      const response = await fetchModules();
      setModules(response);
      
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  // Fetch roles
  const fetchRoles = async () => {
    try {
      const response = await axios.get("https://bacr2024-backend-production.up.railway.app/api/roles");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchModulesb();
    fetchRoles();
  }, []);

  const rolesOptions = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Add a new route
  const addRoute = () => {
    setFormData((prev) => ({
      ...prev,
      routes: [...prev.routes, { path: "", method: "GET", description: "" }],
    }));
  };

  // Remove a route
  const removeRoute = (index) => {
    const updatedRoutes = formData.routes.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, routes: updatedRoutes }));
  };

  // Handle form submission for adding module
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // await axios.post("https://bacr2024-backend-production.up.railway.app/api/module", formData);
      await createModule(formData);
      toast({
        title: 'Module Added Successfully.',
        description: "The module has been added successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setFormData({
        name: "",
        description: "",
        routes: [{ path: "", method: "GET", description: "" }],
        rolesAllowed: [],
      });
      
      fetchModulesb();
    } catch (error) {
      console.error("Error creating module:", error);
    }
  };

  // Handle update module
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateModule(formData,moduleToUpdate?.id);
      await fetchModulesb();
      toast({
        title: 'Module Updated Successfully.',
        description: "The module has been updated successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setFormData({
        name: "",
        description: "",
        routes: [{ path: "", method: "GET", description: "" }],
        rolesAllowed: [],
      });
      fetchModulesb();
      setModuleToUpdate(null); // Clear module to update state
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });    
    }
  };

  // Handle delete module
  const handleDelete = async (id) => {
    
    try {
      await deleteModule(id);
      toast({
        title: 'Module Deleted Successfully.',
        description: "The module has been deleted successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchModulesb();
    } catch (error) {
      toast({
        title: "An error occurred.",
        status: "error", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });    
    }
  };

  return (
    <Box p={6}>
      <Button
        bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))"
        color={"white"}
        _hover={{
          color: "white",
          bg: "rgb(0 70 113 / var(--tw-bg-opacity, 1))",
        }}
        onClick={() => {
          setModuleToUpdate(null); // Ensure it's a fresh add
          setFormData({
            name: "",
            description: "",
            routes: [{ path: "", method: "GET", description: "" }],
            rolesAllowed: [],
          });
          onOpen();
        }}
      >
        Add Module
      </Button>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Module Name</Th>
            <Th>Description</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {modules?.map((module,index) => (
            <Tr key={module.id}>
              <Td>{index+1}</Td>
              <Td>{module.name}</Td>
              <Td>{module.description}</Td>
              <Td>
                <Button
                   bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
                  onClick={() => {
                    setModuleToUpdate(module);
                    setFormData({
                      name: module.name,
                      description: module.description,
                      routes: module.routes || [{ path: "", method: "GET", description: "" }],
                      rolesAllowed: module.rolesAllowed || [],
                    });
                    onOpen();
                  }}
                >
                  Edit
                </Button>
                {user.roles.includes("superadmin") &&( 
                <Button
                  colorScheme="red"
                  onClick={() => handleDelete(module?.id)}
                  ml={2}
                >
                  Delete
                </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for Adding or Editing Module */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{moduleToUpdate ? "Edit Module" : "Add Module"}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={moduleToUpdate ? handleUpdate : handleSubmit}>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Module Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} mr={3} type="submit"
            >
              {moduleToUpdate ? "Update" : "Save"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
            </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModuleManagement;