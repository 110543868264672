import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { fetchJobById } from "../../api";
import ContentSection from "../Common/ContentSection";
import JobApplicantList from "./JobApplicantList";

const JobView = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [job, setJob] = useState([]);
  const [jobapp, setJobApp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchJob();
  }, [id]);  
  const fetchJob = async () => {
    try {
      const response = await fetchJobById(id);
      if (response) {
          setJob(response.job);
          setJobApp(response.jobapp);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
 console.log(job);
 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Heading as="h1" mb={4}>
        {job?.title}
      </Heading>     
            {job?.email && (
           <ContentSection title={"Email"} desc={job?.email} />
           )}
          {job?.responsb && (
            <ContentSection
              title={"Responsibility"}
              desc={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.responsb) }} />}
            />
            )}
          {job?.requirements && (
           <ContentSection title={"Requirements"} 
           desc={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.responsb) }} />}
            />
           )}
          {job?.skills && (
           <ContentSection title={"Skills"} desc={job?.skills} />
           )}
           {job?.desc && (
           <ContentSection title={"Description"} desc={job?.desc} />
           )}
           {job?.designation && (
           <ContentSection title={"Designation"} desc={job?.designation} />
           )}
           {job?.location && (
           <ContentSection title={"Location"} desc={job?.location} />
           )}
           {job?.deadline && (
           <ContentSection title={"Deadline"} desc={job?.deadline} />
           )}
          {job.status && (
           <ContentSection title={"Status"} desc={job.status} />
           )}
      <JobApplicantList applicants={jobapp} />
    </Box>
  );
};

export default JobView;