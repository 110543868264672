import React, { useState, useEffect, useRef } from "react";
import {
  Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
  Textarea,
  Switch,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchJobs,createJob,editJob,deleteJob} from "../../api";
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from "react-redux";

const JobPage = () => {
  const [jobs, setJobs] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const { user } = useSelector((state) => state.auth);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const cancelRef = useRef();    
  const openDeleteDialog = (projectId) => {
    setJobToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setJobToDelete(null);
    setIsDeleteDialogOpen(false);
  };
  // Fetch Teams
  useEffect(() => {
    fetchJobsb();
  }, []);  
  
  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jobs.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const fetchJobsb = async () => {
    try {
      const response = await fetchJobs();
      setJobs(response);
      console.log(response);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };
  // Handle delete button click
  const handleDelete = async (jobId) => {
    try {
      if (!jobToDelete) return;
      const res=await deleteJob(jobToDelete);
      
      closeDeleteDialog();
      fetchJobsb()
      setJobs(jobs.filter((job) => job.id !== jobId));
    } catch (error) {
      
    }
  };

  return (
    <Box p={5}>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/add-job" mr={3}>
        Add Job
      </Button>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/job-departments">
         Job Departments
      </Button>
      <Table mt={4}>
        <Thead>
          <Tr>
            <Th>Sr</Th>        
            <Th>Title</Th>        
            <Th>Email</Th>
            {/* <Th>Designation</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((job,index) => (
            <Tr key={job.id}>
              <Td>{(currentPage - 1) * itemsPerPage + index + 1}</Td>
              <Td>{job.title}</Td>
              <Td>{job.email}</Td>
              {/* <Td>{job.designation}</Td> */}
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={`/edit-job/${job.id}`} >
                  Edit
                </Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" ml={2} onClick={() => openDeleteDialog(job.id)}>
                  Delete
                </Button>
                )}
                <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/job/${job.id}`}>View</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this job? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default JobPage;