import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
  useToast,
  HStack,
} from "@chakra-ui/react";
import {fetchClients ,createClient,editClient,deleteClient, fetchClientsSrno} from "../../api";
import { useSelector } from "react-redux";

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({sr_no:"", name: "", image: null });
  const [formDatab, setFormDatab] = useState({sr_no:"", name: "", image: null });
  const [editClientId, setEditClientId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [sno, setSno] = useState("");
  const { user } = useSelector((state) => state.auth);
  const toast=useToast();
  // Disclosure hooks for separate modals
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  // Fetch clients
  useEffect(() => {
    fetchClientsb();
  }, []);


  const totalPages = Math.ceil(clients.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clients.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const fetchClientsb = async () => {
    try {
      const response = await fetchClients();
      setClients(response);
      const responseb = await fetchClientsSrno();
      setSno(responseb.sr_no);
      // console.log(responseb);
      
      setFormData((prevData) => ({
        ...prevData,
        sr_no: responseb.sr_no + 1,
      }));
      
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = e.target.files[0];
      
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        console.log(file.size);
        console.log(maxSize);
        
        if (file.size > maxSize) {
            e.target.value="";
          return toast({
            title: "An error occurred.",
            description: "File size should not exceed 2MB.",
            status: "error", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });     
        }
      }
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };
  const handleChangeb = (e) => {
    const { name, value, files } = e.target;
    if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
    setFormDatab((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  // Handle Add Client submission
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const snob=sno+1;
    if(formData.sr_no > snob){
      toast({
        title: 'Error',
        description: "SR No cannot be greater than "+ snob,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return; 
    }
    const formDataToSend = new FormData();
    formDataToSend.append("sr_no", formData.sr_no);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("image", formData.image);

    try {
      await createClient(formDataToSend);

      setFormData({ name: "", image: null });
      handleCloseModal();
      // Refetch clients
      fetchClientsb();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast({
          title: "Error",
          description: "Client name already exists.",
          status: "error",
          duration: 5000, // Duration in milliseconds
          isClosable: true,
        });
      }else{
        toast({
          title: "Unexpected Error",
          description: "Something went wrong. Please try again later.",
          status: "error",
          duration: 5000, // Duration in milliseconds
          isClosable: true,
        });
      }    
    }
  };

  // Handle Edit Client submission
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if(formDatab.sr_no > sno){
      toast({
        title: 'Error',
        description: "SR No cannot be greater than "+ sno,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return; 
    }
    const formDataToSend = new FormData();
    formDataToSend.append("sr_no", formDatab.sr_no);
    formDataToSend.append("name", formDatab.name);
    formDataToSend.append("image", formDatab.image);

    try {
      await editClient(editClientId,formDataToSend);
      setFormDatab({ name: "", image: null });
      setEditClientId(null);
      onEditClose();

      fetchClientsb();
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  // Handle edit button click
  const handleEditClick = (client) => {
    setEditClientId(client.id);
    setFormDatab({sr_no:client.sr_no, name: client.name, image: null });
    onEditOpen();
  };

  // Handle delete button click
  const handleDelete = async (clientId) => {
    try {
      await deleteClient(clientId);
      fetchClientsb();
    } catch (error) {
      
    }
  };
  const handleCloseModal = () => {
    // Reset form data to initial state
    setFormData({
      name: "",
      sr_no: "",
    });
    // Close the modal
    onAddClose();
  };
  return (
    <Box p={5}>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={onAddOpen}>
        Add Client
      </Button>
      <Table mt={4}>
        <Thead>
          <Tr>
            <Th>S.NO</Th>
            <Th>Name</Th>
            <Th>Image</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((client,index) => (
            <Tr key={client.id}>
              <Td>{client.sr_no}</Td>
              <Td>{client.name}</Td>
              <Td>
                <img src={`${client?.imagePath}`} alt={client?.name} width="50" />
              </Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={() => handleEditClick(client)}>
                  Edit
                </Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" ml={2} onClick={() => handleDelete(client.id)}>
                  Delete
                </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      {/* Add Client Modal */}
      <Modal isOpen={isAddOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleAddSubmit}>
              <FormControl isRequired mb={4}>
            <HStack justifyContent="space-between">
              <Box as="span" fontSize="sm" color="gray.500">
                (Last SR NO {sno})
                </Box>
                </HStack>
                <FormLabel>Sr No</FormLabel>
                <Input type="number" name="sr_no" value={formData?.sr_no} onChange={handleChange} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formData.name} onChange={handleChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Image</FormLabel>
                <Input type="file" name="image" onChange={handleChange} accept="image/*" />
              </FormControl>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Add
                </Button>
                <Button onClick={handleCloseModal}>Cancel</Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Edit Client Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleEditSubmit}>
            <FormControl isRequired mb={4}>
            <HStack justifyContent="space-between">
              <Box as="span" fontSize="sm" color="gray.500">
                (Last SR NO {sno})
                </Box>
                </HStack>
                <FormLabel>Sr No</FormLabel>
                <Input type="number" name="sr_no" value={formDatab?.sr_no} onChange={handleChangeb} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formDatab.name} onChange={handleChangeb} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Image</FormLabel>
                <Input type="file" name="image" onChange={handleChangeb} accept="image/*" />
              </FormControl>
              <ModalFooter>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} mr={3} type="submit">
                  Update
                </Button>
                <Button onClick={onEditClose}>Cancel</Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ClientManagement;