import { Heading } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

const Unauthorized = () => {
  const location = useLocation();
  const { children } = location.state || {}; // Retrieve children from state

  return (
    <div>
      <Heading justifyContent={"center"} textAlign={"center"} alignItems={"center"}>You are not authorized to access this page</Heading>
    </div>
  );
};

export default Unauthorized;