import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchTeamById,editTeam} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const EditTeam = () => {
    const [responsb, setResponsb] = useState([]);
    const [requir, setRequir] = useState([]);
    const navigate=useNavigate();
    const { id } = useParams(); 
    const toast=useToast();    
    const [teams, setTeam] = useState([]);
    const location = useLocation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",         
        designation: "",
        image:"",
        linkedIn:"",
        bio:""
      });  
      useEffect(() => {
        fetchTeamsb();
      }, [id]);  
      useEffect(() => {
              // Check if the current path is "/edit-blog/:id"
              if (location.pathname.startsWith("/edit-team/")) {
                // Prevent the user from going back to the previous page
                window.history.pushState(null, null, window.location.href);  // Add a new history entry
                window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
          
                // Handle the back/forward navigation event
                const handlePopState = () => {
                  navigate("/teams");  // Redirect to /blogs page if back is triggered
                };
          
                // Set the popstate event handler
                window.addEventListener("popstate", handlePopState);
          
                // Cleanup the event listener when the component unmounts or the path changes
                return () => {
                  window.removeEventListener("popstate", handlePopState);
                };
              }
          
              // Cleanup for other routes (this does nothing for non-edit pages)
              return () => {};
            }, [navigate, location.pathname]);
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          bio: content,
        }));
      };
      const fetchTeamsb = async () => {
        try {
          const response = await fetchTeamById(id);
          setFormData(response);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };
      const handleEditSubmit = async (e) => {
        e.preventDefault();
        if(formData.name =='' || formData.email =='' ){
          return toast({
            title: "An error occurred.",
            description: "Name and Email Field are Required",
            status: "error", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });     
        }
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("designation", formData.designation);
        formDataToSend.append("bio", formData.bio);
        formDataToSend.append("linkedIn", formData.linkedIn);
        formDataToSend.append("image", formData.image);  // Only append if image is uploaded
        try {
          const response= await editTeam(formDataToSend,id);
          setFormData({ name: "", image: null });
          toast({
            title: "Action Successful!",
            description: "Form Successfully Updated",
            status: "success", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });
          fetchTeamsb();
          navigate("/teams");
        } catch (error) {
          if (error.response && error.response.status === 404) {
          toast({
            title: "Error",
            description: "User email already exists.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
          });
        }else{
          toast({
            title: "Unexpected Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
          });
        }
      }
      };
    
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in byte          
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg">
    <form onSubmit={handleEditSubmit}>
    <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
      <FormControl isRequired mb={4}>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formData.name} onChange={handleChange} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Email</FormLabel>
                <Input type="email" name="email" value={formData.email} onChange={handleChange} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Designation</FormLabel>
                <Input type="text" name="designation" value={formData.designation} onChange={handleChange} />
              </FormControl>
              <FormControl mb={4}>
                              <HStack justifyContent="space-between">
                              <FormLabel>Breef Bio</FormLabel>
                              <Box as="span" fontSize="sm" color="gray.500">
                                (Max words 250)
                              </Box>
                            </HStack>
                            <ReactQuill
                              theme="snow"
                                maxLength={250}
                                value={formData.bio}
                                onChange={handleDescriptionChange}
                                placeholder="Write your content here..."
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                                    ["bold", "italic", "underline", "strike"], // Text styles
                                    [{ list: "ordered" }, { list: "bullet" }], // Lists
                                    ["link", "image", "video"], // Links, Images, Videos
                                    ["clean"], // Remove formatting
                                    [{ align: [] }],
                                  ],
                                  imageResize: {
                                    modules: ['Resize', 'DisplaySize'],
                                  },
                                }}
                              />   
                            </FormControl>
              <FormControl mb={4}>
                <FormLabel>Image</FormLabel>
                <Input type="file" name="image" onChange={handleChange} accept="image/*" />
              </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Team
                </Button>
                </VStack>
      </VStack>
      </form>
  </Box>
  )
}

export default EditTeam;