import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {fetchSocialLinks,createSocialLink} from "../../api";
import { useNavigate, useParams } from "react-router-dom";

const AddCertificates = () => {
    const navigate=useNavigate();
    const toast=useToast();
    const [formData, setFormData] = useState([]);
      const allowedFileTypes = ['image/jpeg','image/jpg', 'image/png'];

    const handleAddSubmit = async (e) => {
        e.preventDefault();    
        try {
        const formDatab = new FormData();
        if(formData.certificate1){
        formDatab.append('certificate1', formData.certificate1);
        }
        if(formData.certificate2){
        formDatab.append('certificate2', formData.certificate2);
        }
        if(formData.certificate3){
        formDatab.append('certificate3', formData.certificate3);
        }
        if(formData.certificate4){
        formDatab.append('certificate4', formData.certificate4);
        }
        const response= await createSocialLink(formDatab);
        navigate("/contacts");
        toast({
        title: "Action Successful!",
        description: "Form Successfully Submitted",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
        });
    } catch (error) {
        toast({
        title: "Unexpected Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 5000, // Duration in milliseconds
        isClosable: true,
        });
    
    }
      };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        const selectedFile = files ? files[0] : null;
        if(selectedFile){
        if (!allowedFileTypes.includes(selectedFile.type)) {
          e.target.value = null;
          return toast({
            title: "Unexpected Error",
            description: "Invalid file type. Only JPG, PNG, and JPEG are allowed.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
            });            
          }
        }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl>
          <HStack justifyContent="space-between">
                <FormLabel>Certificate 1</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (jpg / jpeg / png )
                </Box>
                </HStack>
          <Input
            type="file"
            name="certificate1"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <HStack justifyContent="space-between">
                <FormLabel>Certificate 2</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (File Type jpg / jpeg / png)
                </Box>
                </HStack>
          <Input
            type="file"
            name="certificate2"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
        <HStack justifyContent="space-between">
                <FormLabel>Certificate 3</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (File Type jpg / jpeg / png )
                </Box>
                </HStack>
          <Input
            type="file"
            name="certificate3"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
        <HStack justifyContent="space-between">
                <FormLabel>Certificate 4</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (File Type jpg / jpeg / png )
                </Box>
                </HStack>
          <Input
            type="file"
            name="certificate4"
            onChange={handleChange}
          />
        </FormControl>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Certificates
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddCertificates;