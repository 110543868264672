import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, HStack, Stack, Text } from "@chakra-ui/react"
import ProductPage from "./ProductPage";
import Select from 'react-select';
import { Link as RouterLink} from 'react-router-dom';
import { downloadProduct, fetchProdBrands, fetchProductByBrand, fetchProducts } from "../../api";

const Brands = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [brandsOpts, setBrandsOptns] = useState([]);
  const [brand, setBrand] = useState("");
  const [addpro, setAddpro] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products based on criteria
  const [filter, setFilter] = useState(null);


  const brandOptions = brands.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));
  // Handle brand filter change
  const handleBrandChange = (e) => {
      const selectedBrandId = e.target.value;
      setBrand(selectedBrandId); // Update the selected brand state
  };

  // Handle category filters (new, used, spare)
  const handleCategoryFilter = (category) => {
      setFilter(category);
      const updatedOptions = brandOptions?.filter((option) => {
        if (option.value === 'spare') {
          return filteredProducts?.some(
            (product) => product?.brandFlags?.spare
          );
        }
        if (option.value === 'used') {
          return filteredProducts?.some(
            (product) => product?.brandFlags?.used
          );
        }
        if (option.value === 'new') {
          return filteredProducts?.some(
            (product) => product?.brandFlags?.new
          );
        }
        return true;
      });
      setBrandsOptns(updatedOptions);
  };


  // Apply all filters (brand + category)
  const applyFilters = () => {
    let filtered = products?.products;
    if(brand !==""){
    const isFilterInBrands = () => {
      return products?.brands?.some((brandItem) => {
        // Check if the brand ID matches the selected brand ID
        if (brand && brandItem?.id !== brand) {
          return false; // Skip if the brand ID does not match
        }

    
        // Check the filter against the corresponding brand properties
        if (filter === 'new') return brandItem?.newProd === 1;
        if (filter === 'used') return brandItem?.usedProd === 1;
        if (filter === 'spare') return brandItem?.spPrt === 1;
        return false;
      });
    };
    
    // Set addPro to true if the selected brand and filter exist in brands
    if (isFilterInBrands()) {
      setAddpro(true);
    }else{
      setAddpro(false);
    }
  }

    if (brand) {
      filtered = filtered?.filter(product => product?.Brand?.id === brand);
    }

    if (filter) {
      // Filter products based on category (new, used, spare)
      if (filter === 'new') {        
          filtered = filtered?.filter(product => 
              product?.brandFlags?.newProd === true && product.proType === 'new'
          );
      } else if (filter === 'used') {
        
          filtered = filtered?.filter(product => 
              product.brandFlags.usedProd === true && product.proType === 'used'
          );
      } else if (filter === 'spare') {
        
          filtered = filtered?.filter(product => 
              product.brandFlags.spare === true && product.proType === 'spare'
          );
      }
    }

    setFilteredProducts(filtered);
  };


  
  const fetchProductsb = async () => {
      try {
          const response = await fetchProducts();
          
          setProducts(response);
          setFilteredProducts(response.products); // Initially, set all products as filtered
      } catch (error) {
          console.error("Error fetching products:", error);
      }
  };

  // Fetch brands from the API
  const fetchBrands = async () => {
      try {
          const responseb = await fetchProdBrands();
          setBrands(responseb);
          setBrandsOptns(responseb);
      } catch (error) {
          console.error("Error fetching brands:", error);
      }
  };
    const downloadProducts = async () => {
    try {
      const response = await downloadProduct(filter);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  // Re-apply filters whenever `products`, `brand`, or `filter` changes
  useEffect(() => {
      applyFilters();
  }, [products, brand, filter]);

  // Fetch data when the component mounts
  useEffect(() => {
      fetchProductsb();
      fetchBrands();
  }, []);
  return (
    <>
       <HStack
  spacing={{ base: '16px', md: '24px', lg: '32px' }}
  wrap="wrap" // Ensure proper wrapping on small screens
  align="left"
  justify={{ base: 'center', md: 'flex-start' }} // Center on small screens, left-align on larger screens
  w="100%"
  p={{ base: '4', md: '6' }}
>
  <Button
    bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
    variant="solid"
    size="md"
    borderRadius="full"
    bg={filter === 'new' ? 'white' : 'teal'}
    color={filter === 'new' ? 'teal' : 'white'}
    boxShadow="md"
    onClick={() => handleCategoryFilter("new")}
    leftIcon={<i className="fas fa-box" />} // Replace with relevant icon
    _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
  >
    New Products
  </Button>
  <Button
    bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
    variant="solid"
    size="md"
    bg={filter === 'used' ? 'white' : 'teal'}
    color={filter === 'used' ? 'teal' : 'white'}
    borderRadius="full"
    boxShadow="md"
    onClick={() => handleCategoryFilter("used")}
    leftIcon={<i className="fas fa-sync-alt" />} // Replace with relevant icon
    _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
  >
    Used Products
  </Button>
  <Button
    bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
    variant="solid"
    size="md"
    borderRadius="full"
    boxShadow="md"
    bg={filter === 'spare' ? 'white' : 'teal'}
    color={filter === 'spare' ? 'teal' : 'white'}
    onClick={() => handleCategoryFilter("spare")}
    leftIcon={<i className="fas fa-cogs" />} // Replace with relevant icon
    _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
  >
    Spare Parts
  </Button>
  <Select
      options={brandOptions}
      placeholder="Select Brand"
      isSearchable
      onChange={(selectedOption) => handleBrandChange({ target: { value: selectedOption?.value } })}
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: 'full',
          boxShadow: 'md',
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? 'teal' : 'white',
          color: 'black',
        }),
      }}
    />
    <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={`/categories`} >
         View Categories
    </Button>
    {filter !==null &&
    <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={() => downloadProducts()} >
         Download ProductList
    </Button>
    }
</HStack>
    {addpro &&
    <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={`/add-product/${brand}/${filter}`} >
        Add Product
      </Button>
      }
{filteredProducts?.length > 0 &&
       <ProductPage products={filteredProducts} brand={brand} addpro={addpro} fetchProductsc={fetchProductsb} />
      }
       </>
  )
}
export default Brands;