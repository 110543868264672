import React, { useEffect, useState } from "react";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, useDisclosure,useToast, 
  Flex
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { fetchBlogCategories,createBlogCategory,deleteBlogCategory,editBlogCategory, fetchUsers} from "../../api";
import { useSelector } from "react-redux";

function UserTable() {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
  });
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useSelector((state) => state.auth);
  const fetchUsersb = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  // Fetch all users
  useEffect(() => {
    fetchUsersb();
  },[]);
  
  const totalPages = Math.ceil(users.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };


  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle project update submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isFormInvalid = Object.values(formData).some(value => !value);
    const requiredFields = ["name"];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
  if (emptyFields.length > 0) {
    // Join the empty field names into a readable string
    const emptyFieldsMessage = `All fields are required.`;
    toast({
      title: 'Error',
      description: emptyFieldsMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  
    return; // Stop further execution
  }
    try {
      if (selectedCategory) {
        await editBlogCategory(selectedCategory.id,formData);
        fetchUsersb();
        toast({
          title: 'Category Updated Successfully.',
          description: "The category has been updated successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
      } else {
        await createBlogCategory(formData);
      fetchUsersb();

        toast({
          title: 'Category Added Successfully.',
          description: "The category has been added successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
      setFormData({ name: ""});
      setSelectedCategory(null);
    } catch (error) {
      if(selectedCategory){
      toast({
        title: 'Error',
        description: "There was an error updating the category.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }else{
      toast({
        title: 'Error',
        description: "There was an error adding the category.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    }
  };

  // Delete project
  const deleteCategoryb = async (id) => {
    try {
      await deleteBlogCategory(id);
      fetchUsersb();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <div>
      <Button mr={2} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/add-user"}>Add User</Button>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/roles"}>Assign Modules</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Role</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((user,index) => (
            <Tr key={user?.id}>
              <Td>{index+1}</Td>
              <Td>{user?.name}</Td>
              <Td>{user?.roleDetails?.name}</Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/assign-role/${user?.id}`}>{user?.roleDetails?.name ? "Update Role" : "Assign Role" } </Button>
                {/* {user.roles.includes("admin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => deleteUserb(user.id)}>Delete</Button>
                )} */}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCategory ? "Update Blog Category" : "Add Blog Category"}</ModalHeader>
          <ModalBody>
            <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={handleSubmit}>
              {selectedCategory ? "Update" : "Add"} Category
            </Button>
            <Button onClick={onClose} ml={3}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default UserTable;