
import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, Select, useDisclosure,useToast, 
  Flex
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { fetchTags,createTag,deleteTag,editTag} from "../../api";
import { useSelector } from "react-redux";

function TagTable() {
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
  });
  const { user } = useSelector((state) => state.auth);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedTag, setSelectedTag] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchTagsb = async () => {
    try {
      const response = await fetchTags();
      setTags(response);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };
  // Fetch all categories
  useEffect(() => {
    fetchTagsb();
  },[]);
  
  const totalPages = Math.ceil(tags.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tags.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle tag update submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isFormInvalid = Object.values(formData).some(value => !value);
    const requiredFields = ["name"];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
  if (emptyFields.length > 0) {
    // Join the empty field names into a readable string
    const emptyFieldsMessage = `All fields are required.`;
    toast({
      title: 'Error',
      description: emptyFieldsMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return; // Stop further execution
  }
    try {
      if (selectedTag) {
        await editTag(selectedTag.id,formData);
        fetchTagsb();
        toast({
          title: 'Tag Updated Successfully.',
          description: "The tag has been updated successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
      } else {
        await createTag(formData);
        fetchTagsb();

        toast({
          title: 'Tag Added Successfully.',
          description: "The tag has been added successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
      setFormData({ name: ""});
      setSelectedTag(null);
    } catch (error) {
      if(selectedTag){
      toast({
        title: 'Error',
        description: "There was an error updating the tag.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }else{
      toast({
        title: 'Error',
        description: "There was an error adding the tag.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    }
  };

  // Open modal and populate form with tag data for editing
  const openEditModal = (tag) => {
    setSelectedTag(tag);
    
    setFormData({ ...tag });
    onOpen();
  };

  // Delete tag
  const deleteTagb = async (id) => {
    try {
      await deleteTag(id);
      fetchTagsb();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/add-tag">Add Tag</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((tag,index) => (
            <Tr key={tag.id}>
              <Td>{index+1}</Td>
              <Td>{tag.name}</Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/edit-tag/${tag.id}`}>Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => deleteTagb(tag.id)}>Delete</Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </div>
  );
}

export default TagTable;