import React, { useState, useEffect } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';

const CertificateViewer = ({ pdfFile }) => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Fetch PDF URL asynchronously (if needed)
    setTimeout(() => {
      setIsLoading(false);
    }, 500)
  }, [pdfFile]); // Empty dependency array to run only on initial render

  return (
    <>
      {isLoading ? (
        <p>Loading PDF...</p>
      ) : (
        <PDFViewer  document={{ url: pdfFile }} />
      )}
    </>
  );
};

export default CertificateViewer;