import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {createBrand} from "../../api";
import { useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const AddProdBrand = () => {
    const [responsb, setResponsb] = useState([]);
    const [requir, setRequir] = useState([]);
    const navigate=useNavigate();
    const toast=useToast();
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [subBrand, setsubBrand] = useState(false);
    const [isToggleOnb, setIsToggleOnb] = useState(false);
    const [isToggleOnc, setIsToggleOnc] = useState(false);
    const [isToggleOnd, setIsToggleOnd] = useState(false);
    const [formData, setFormData] = useState({
        name:"",
        spPrt:"",
        website:"",
        newProd:"",
        usedProd:"",
        details:"",
        authorize:"",
        coverimage:"",
        image:"",
      });

      const handleToggleChangec = (e) => {
        setIsToggleOnc(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            spPrt: "1", // Set to "1" if toggle is ON
          });
        } else {
          setFormData({
            ...formData,
            spPrt: "", // Reset to empty when toggle is OFF
          });
        }
      };
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          details: content,
        }));
      };
      const handleToggleChanged = (e) => {
        setIsToggleOnd(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            authorize: "1", // Set to "1" if toggle is ON
          });
        } else {
          setFormData({
            ...formData,
            authorize: "", // Reset to empty when toggle is OFF
          });
        }
      };
      const handleToggleChange = (e) => {
        setIsToggleOn(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            newProd: "1", // Set to "1" if toggle is ON
          });
        } else {
          setFormData({
            ...formData,
            newProd: "", // Reset to empty when toggle is OFF
          });
        }
      };
      const handleToggleChangeb = (e) => {
        setIsToggleOnb(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            usedProd: "1", // Set to "1" if toggle is ON
          });
        } else {
          setFormData({
            ...formData,
            usedProd: "", // Reset to empty when toggle is OFF
          });
        }
      };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        if(formData.name =='' || formData.email =='' || formData.designation ==''){
          return toast({
            title: "An error occurred.",
            description: "All fields are required",
            status: "error", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });     
        }
        
        try {
          const formDatab = new FormData();
          formDatab.append('name', formData.name);
          formDatab.append('spPrt', formData.spPrt);
          formDatab.append('website', formData.website);
          formDatab.append('details', formData.details);
          formDatab.append('image', formData.image);
          if(subBrand) formDatab.append('category', "subbrand");
          if(!subBrand) formDatab.append('category', "brand");
          formDatab.append('coverimage', formData.coverimage);
          formDatab.append('newProd', formData.newProd);
          formDatab.append('usedProd', formData.usedProd);
          formDatab.append('authorize', formData.authorize);        

          const response= await createBrand(formDatab);
          navigate("/brands");
          toast({
            title: "Action Successful!",
            description: "Form Successfully Submitted",
            status: "success", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });
        } catch (error) {
          toast({
            title: "Unexpected Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
          });
        
      }
      };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={formData.title}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel htmlFor="show-price" mb="0">
          Sub Brand
        </FormLabel>
        <Switch
          id="show-price"
          isChecked={subBrand}
          onChange={() => setsubBrand((prev) => !prev)}
        />
      </FormControl>
      {!subBrand && (
          <>
        <FormControl>
          <FormLabel>Website</FormLabel>
          <Input
            type="text"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
        </FormControl>      
        <FormControl isRequired>
          <FormLabel>Details</FormLabel>
          <ReactQuill
                theme="snow"
                  maxLength={250}
                  value={formData?.details}
                  onChange={handleDescriptionChange}
                  placeholder="Write your content here..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                      ["bold", "italic", "underline", "strike"], // Text styles
                      [{ list: "ordered" }, { list: "bullet" }], // Lists
                      ["link", "image", "video"], // Links, Images, Videos
                       [{ align: [] }], 
                      ["clean"], // Remove formatting
                    ],
                    imageResize: {
                      modules: ['Resize', 'DisplaySize'],
                    },
                  }}
                />                 
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Logo</FormLabel>
          <Input
            type="file"
            name="image"
            accept="image/jpeg, image/png , image/jpg"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Cover Image</FormLabel>
          <Input
            type="file"
            name="coverimage"
            accept="image/jpeg, image/png , image/jpg"
            onChange={handleChange}
          />
        </FormControl>
        </>
      )}
        <HStack spacing={4} align="stretch" justifyContent={"center"}>
        <FormControl >
          <FormLabel>New</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOn}
          onChange={handleToggleChange}
          mr={3}
        />
      </Box>
        </FormControl>
        <FormControl >
          <FormLabel>Used</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOnb}
          onChange={handleToggleChangeb}
          mr={3}
        />
      </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Spare Parts</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOnc}
          onChange={handleToggleChangec}
          mr={3}
        />
      </Box>
        </FormControl>
        </HStack>  
        <HStack justifyContent="space-between">
          <FormLabel>Authorize</FormLabel>
          <Switch
            name="status"
            isChecked={isToggleOnd}
            onChange={handleToggleChanged}
          />
          <FormLabel>
          <Box
          as="mark"
          bg={isToggleOnd ? "blue.100" : "red.100"}
          color={isToggleOnd ? "blue.700" : "red.700"}
          px={2}
          py={1}
          borderRadius="md"
        >
          {isToggleOnd  ? "Authorize" : "Unauthorize"}
        </Box>
          </FormLabel>
          </HStack>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Brand
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddProdBrand
