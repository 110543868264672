import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Select,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchJobById,editJob, fetchDepartments} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const EditJob = () => {
    const { id } = useParams(); 
    const [responsb, setResponsb] = useState("");
    const [editJobId, setEditJobId] = useState([]);
    const [loading, setLoading] = useState([]);
    const [departs, setDeparts] = useState([]);
    const [skills, setSkills] = useState([]);
    const [requir, setRequir] = useState("");
    const navigate=useNavigate();
    const toast=useToast();
    const location = useLocation();
    const [formData, setFormData] = useState({
      title:"",
      desc:"",
      email:"",
      bio:"",
      designation:"",
      department:"",
      location:"",
      deadline:"",
      status:"",
      type:"",
    });

    useEffect(() => {
        fetchJob();
      }, [id]);  
      useEffect(() => {
        // Check if the current path is "/edit-blog/:id"
        if (location.pathname.startsWith("/edit-job/")) {
          // Prevent the user from going back to the previous page
          window.history.pushState(null, null, window.location.href);  // Add a new history entry
          window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
    
          // Handle the back/forward navigation event
          const handlePopState = () => {
            navigate("/jobs");  // Redirect to /blogs page if back is triggered
          };
    
          // Set the popstate event handler
          window.addEventListener("popstate", handlePopState);
    
          // Cleanup the event listener when the component unmounts or the path changes
          return () => {
            window.removeEventListener("popstate", handlePopState);
          };
        }
    
        // Cleanup for other routes (this does nothing for non-edit pages)
        return () => {};
      }, [navigate, location.pathname]);
      
      const fetchJob = async () => {
        try {
          const response = await fetchJobById(id);
          
          if (response) {
            
            setFormData({
              title:response?.job?.title,
              desc:response.job?.desc,
              email:response.job?.email? response.job?.email :"",
              bio:response.job?.bio? response.job?.bio :"",
              designation:response.job?.designation ? response.job?.designation :"",
              department:response.job?.department ?response.job?.department :"",
              location:response.job?.location ? response.job?.location : "",
              deadline:response.job?.deadline ? response.job?.deadline : "",
              status:response.job?.status ? response.job?.status:""
            });
            setFormData(response.job);
            setEditJobId(response.job.id);
            const skillsArray = response.job.skills ? response.job.skills.split(',').map(skill => {
                return {
                  label: skill.trim(),
                  value: skill.trim()  // You can adjust the 'value' if you want a different identifier for each email
                };
              }) : [];
              setSkills(skillsArray);
              setResponsb(response.job?.responsb);  
              
              // const requirArray = response.requirements ? response.requirements.split(',').map(requirement => {
              //   return {
              //     label: requirement.trim(),
              //     value: requirement.trim()  // You can adjust the 'value' if you want a different identifier for each email
              //   };
              // }) : [];
              setRequir(response.job?.requirements); 
              const responseb = await fetchDepartments();
              setDeparts(responseb);
          }
        } catch (err) {
            toast({
                title: "Unexpected Error",
                description: "Something went wrong. Please try again later.",
                status: "error",
                duration: 5000, // Duration in milliseconds
                isClosable: true,
              });
            
        } finally {
          setLoading(false);
        }
      };
      const handleResponsbChange = (selectedOptions) => {    
        setResponsb(selectedOptions);
      };
      const handleSkillsChange = (content) => {
        setSkills(content);      
      };
      const handleRequirChange = (selectedOptions) => {    
        setRequir(selectedOptions);
      };
      const handleEditSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        if (formData.title !== "") formDataToSend.append("title", formData.title);
        if (formData.desc !== "") formDataToSend.append("desc", formData.desc);
        if (formData.email !== "") formDataToSend.append("email", formData.email);
        if (formData.bio !== "") formDataToSend.append("bio", formData.bio);    
        if (formData.designation !== "") formDataToSend.append("designation", formData?.designation);
        if (formData.department !== "") formDataToSend.append("department", formData?.department);
        if (formData.location !== "") formDataToSend.append("location", formData.location);
        if (formData.deadline !== "") formDataToSend.append("deadline", formData.deadline);
        if (formData.status !== "") formDataToSend.append("status", formData.status);
        if (formData.type !== "") formDataToSend.append("type", formData.type);
        // formDataToSend.append("deadline", formData.deadline);
        // formDataToSend.append("image", formData.image);
          formDataToSend.append('responsb', responsb);
          formDataToSend.append('requir', requir);
          skills.forEach(skill => {
            formDataToSend.append('skills[]', skill.value);
          });
        try {      
          await editJob(formDataToSend,editJobId);
          toast({
            title: "Action Successful!",
            description: "Form Successfully Updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setFormData({
            title: "", 
            model: "",
            aval: "",
            price: "",
            detail: ""  
          });
          navigate("/jobs")
          
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // Show toast error for 404
            toast({
              title: "Error",
              description: "Job not found.",
              status: "error",
              duration: 5000, // Duration in milliseconds
              isClosable: true,
            });
          } else {
            // Handle other errors
            toast({
              title: "Unexpected Error",
              description: "Something went wrong. Please try again later.",
              status: "error",
              duration: 5000, // Duration in milliseconds
              isClosable: true,
            });
          }
        }
      };


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleEditSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
        <HStack justifyContent="space-between">
    <FormLabel>Email</FormLabel>
    <Box as="span" fontSize="sm" color="gray.500">
      (Send email Info@bacr.com.pk)
    </Box>
  </HStack>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Department</FormLabel>
          <Select name="department" value={formData.department} onChange={handleChange} placeholder="Select Department">
              {departs.map((depart) => (
                <option value={depart.id}>{depart.name}</option>
              ))}
              </Select>
        </FormControl>
        <FormControl >
          <FormLabel>Designation</FormLabel>
          <Input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl >
          <FormLabel>Responsibilities</FormLabel>
          {/* <CreatableSelect
            isMulti
            value={responsb}
            onChange={handleResponsbChange}
            options={[]}
            placeholder="Add or enter responsibilities"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          /> */}
          <ReactQuill
            theme="snow"
              maxLength={250}
              value={responsb}
              onChange={handleResponsbChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  [{ align: [] }],
                  ["clean"], // Remove formatting
                ],
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />
        </FormControl>

        <FormControl>
          <FormLabel>Requirements</FormLabel>
          {/* <CreatableSelect
            isMulti
            value={requir}
            onChange={handleRequirChange}
            options={[]}
            placeholder="Add or enter requirements"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          /> */}
          <ReactQuill
            theme="snow"
              maxLength={250}
              value={requir}
              onChange={handleRequirChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  [{ align: [] }],
                  ["clean"], // Remove formatting
                ],
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />
        </FormControl>
        <FormControl>
          <FormLabel>Skills</FormLabel>
          <CreatableSelect
            isMulti
            value={skills}
            onChange={handleSkillsChange}
            options={[]}
            placeholder="Add or enter skills"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            type="text"
            name="desc"
            value={formData?.desc}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Type</FormLabel>
        <Select name="type" value={formData.type} onChange={handleChange} placeholder="Select Type">
        <option value="parttime">Part Time</option>
        <option value="fulltime">Full Time</option>                      
      </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Location</FormLabel>
          <Textarea
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Application Deadline</FormLabel>
          <Input
            type="date"
            name="deadline"
            value={formData.deadline}
            onChange={handleChange}
          />
        </FormControl>

        <HStack justifyContent="space-between">
          <FormLabel>Status</FormLabel>
          <Switch
            name="status"
            isChecked={formData.status === "publish"}
            onChange={(e) =>
              handleChange({
                target: {
                  name: "status",
                  value: e.target.checked ? "publish" : "unpublish",
                },
              })
            }
          />
           <FormLabel>
          <Box
    as="mark"
    bg={formData.status === "publish" ? "blue.100" : "red.100"}
    color={formData.status === "publish" ? "blue.700" : "red.700"}
    px={2}
    py={1}
    borderRadius="md"
  >
    {formData.status === "publish" ? "Published" : "Unpublished"}
  </Box>
          </FormLabel>
        </HStack>
        <VStack justifyContent="flex-start">
        {/* <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} mr={3} type="submit"> */}
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Job
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default EditJob;