import React, { useState, useEffect, useRef } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
    Select,
  } from "@chakra-ui/react";
  import ReactQuill,{Quill} from "react-quill";
  import CreatableSelect from "react-select/creatable";
import { createBlog, createCertificate, editBlog, fetchBlogById, fetchBlogCategories, fetchBlogs, fetchCertificateSrno, fetchTags, getAllBlogsUrl} from "../../api";
import { useLocation, useNavigate, useParams  } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
const DirectionStyle = Quill.import('attributors/style/direction');
const BlockEmbed = Quill.import('blots/block/embed');

class ClearBlot extends BlockEmbed {
  static create() {
    const node = super.create();
    node.className = 'clear';
    return node;
  }
}
// Register the blot
ClearBlot.blotName = 'clear';
ClearBlot.tagName = 'div';
Quill.register('modules/imageResize', ImageResize);
Quill.register(DirectionStyle, true);
Quill.register(ClearBlot);




const EditBlog = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const { id } = useParams();
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [emails, setEmails] = useState([]);
    const [socTags, setsocTags] = useState([]);
    const [focusKeys, setfocusKeys] = useState([]);
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const [blogsUrl, setBlogsUrl] = useState([]);

    const [selectedBlog, setSelectedBlog] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        description:"",
        image:"",
        meta_desc:"",
        meta_title:"",
        caption_img:"",
        alt_text:"",
        can_url:"",
        excerpt:"",
        status:"",
        schedule_time:""
      });
      const handleTagChange = (selectedOptions) => {    
        setSelectedTags(selectedOptions);
      };
      const quillRef = useRef(null);

      const downloadExcel = () => {
        const excelFileUrl = "/dummyTemplate.xlsx";  // Path to your Excel file in the public folder
        const link = document.createElement("a");
        link.href = excelFileUrl;
        link.download = "dummyTemplate.xlsx";  // Default filename for download
        link.click();  // Trigger the download
      };
    
      // Function to handle the file download for PDF
      const downloadPdf = () => {
        const pdfFileUrl = "/emails.pdf";  // Path to your PDF file in the public folder
        const link = document.createElement("a");
        link.href = pdfFileUrl;
        link.download = "emails.pdf";  // Default filename for download
        link.click();  // Trigger the download
      };
      const generateSlug = (title) => {
        return title
          .toLowerCase()
          .replace(/\s+/g, '-')         // Replace spaces with hyphens
          .replace(/[^\w\-]+/g, '')     // Remove all non-word characters (except hyphens)
          .replace(/\-\-+/g, '-')       // Replace multiple hyphens with a single one
          .replace(/^-+/, '')           // Remove hyphen at the start of the string
          .replace(/-+$/, '');          // Remove hyphen at the end of the string
      };
      // Function to generate unique URL
      const generateUniqueUrl = async (title,blogsUrl) => {
        let uniqueUrl = await generateSlug(title);

        let suffix = 1;
        if(formData.url !==uniqueUrl){
        // Check if the generated URL already exists
        while (blogsUrl.some(blog => blog.url === uniqueUrl)) {
          uniqueUrl = `${uniqueUrl}-${suffix}`;
          suffix++;
        }
      }
        
        return uniqueUrl; // Return the unique URL
      };
      const fetchBlogsb = async () => {
        try {
          const response = await fetchBlogById(id);
          console.log("response "+response.categoryId);
          
          setFormData({
            name: response?.name,
            description:response?.description,
            meta_desc:response?.meta_desc,
            meta_title:response?.meta_title,
            caption_img:response?.caption_img,
            alt_text:response?.alt_text,
            can_url:response?.can_url,
            categoryId:response?.categoryId,
            url:"https://bacr.com.pk/blog-detail/"+response?.url,
            excerpt:response?.excerpt,
            status:response?.status,
            schedule_time:response?.schedule_time
          });
          const url =  await getAllBlogsUrl();
          const filteredUrls = url.filter(blog => blog.url !== response?.url);
          // Set the filtered URLs
          setBlogsUrl(filteredUrls);
          if(response?.tags){
          const mappedTags = response?.tags?.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }));
        
          setSelectedTags(mappedTags);
        }
          const stArray = response.soc_tags ? response.soc_tags.split(',').map(soc_tag => {
            return {
              label: soc_tag.trim(),
              value: soc_tag.trim()
            };
          }) : [];
          setsocTags(stArray);
          const fkArray = response.focus_keys ? response.focus_keys.split(',').map(focus_key => {
            return {
              label: focus_key.trim(),
              value: focus_key.trim()
            };
          }) : [];
          setfocusKeys(fkArray);
          const emailArray = response.emails ? response.emails.split(',').map(email => {
            return {
              label: email.trim(),
              value: email.trim()  // You can adjust the 'value' if you want a different identifier for each email
            };
          }) : [];
          setEmails(emailArray);  
          const responseb = await fetchBlogCategories();
          setCategories(responseb);
          const responsec = await fetchTags();
          setTags(responsec);
          setSelectedBlog(response?.id)
          
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      };
      // console.log(tags[0].name);
      // Fetch all blogs
      useEffect(() => {
        // Check if the current path is "/edit-blog/:id"
        if (location.pathname.startsWith("/edit-blog/")) {
          // Prevent the user from going back to the previous page
          window.history.pushState(null, null, window.location.href);  // Add a new history entry
          window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
    
          // Handle the back/forward navigation event
          const handlePopState = () => {
            navigate("/blogs");  // Redirect to /blogs page if back is triggered
          };
    
          // Set the popstate event handler
          window.addEventListener("popstate", handlePopState);
    
          // Cleanup the event listener when the component unmounts or the path changes
          return () => {
            window.removeEventListener("popstate", handlePopState);
          };
        }
    
        // Cleanup for other routes (this does nothing for non-edit pages)
        return () => {};
      }, [navigate, location.pathname]);
      useEffect(() => {
        fetchBlogsb(); 
      }, [quillRef.current]);
      // useEffect(() => {
      //   if (location.pathname.startsWith("/edit-blog/")) {
      //   window.history.pushState(null, null, window.location.href);  // Add a new history entry
      //   window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
    
      //   // Handle the back/forward navigation event
      //   const handlePopState = (event) => {
      //     navigate("/blogs");
      //   };
      //   window.onpopstate = () => setTimeout(handlePopState, 0);
      //   return () => {
      //     window.onpopstate = () => setTimeout(handlePopState, 0);
      //     };
      // }

      // }, [navigate]);
      const handleClearButtonClick = () => {
        
        if (quillRef.current && quillRef.current.getEditor()) { 
          const range = quillRef.current.getEditor().getSelection(); 
          
          if (range) {
            quillRef.current.getEditor().insertEmbed(range.index, 'clear', true);
          }
        }
      };
      const processFormDataUrl = (url) => {
        const prefix = '/blog-detail/';
        const parts = url.split(prefix);
        return parts.length > 1 ? parts[1] : null;
      };
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      const handleEmailSelectChange = (selectedOptions) => {
        const validOptions = selectedOptions.filter((option) =>
          validateEmail(option.value)
        );
        setEmails(validOptions);
      };
      
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          description: content,
        }));
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
      if (formData.name==="" || formData.description==="") {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `Name and Content, fields are required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
      const slug = processFormDataUrl(formData.url);
        try {
          const formDatab = new FormData();
          formDatab.append('name', formData.name);
          formDatab.append('url', slug);
          formDatab.append('description', formData.description);
          formDatab.append('image', formData.image);
          formDatab.append('categoryId', formData.categoryId);
          formDatab.append('can_url', formData.can_url);
          formDatab.append('alt_text', formData.alt_text);
          formDatab.append('caption_img', formData.caption_img);
          formDatab.append('meta_title', formData.meta_title);
          formDatab.append('meta_desc', formData.meta_desc);
          formDatab.append('status', formData.status);
          formDatab.append('excerpt', formData.excerpt);
          if(formData.status !== "schedule" ){
            formDatab.append('schedule_time', "");
          }else{
            formDatab.append('schedule_time', formData.schedule_time);
          }      
            // if(emails) formDatab.append('emails', emails);
          focusKeys.forEach(focusKey => {
            formDatab.append('focus_keys[]', focusKey.value);
          });
          emails.forEach(email => {
            formDatab.append('emails[]', email.value);
          });
          socTags.forEach(socTag => {
            formDatab.append('soc_tags[]', socTag.value);
          });
          selectedTags.forEach(tagId => {
            formDatab.append('tags[]', tagId.value);
          });
          if (selectedBlog) {
            const idb=selectedBlog;
            await editBlog(idb,formDatab);
            // fetchBlogsb();
            toast({
              title: 'Blog Updated Successfully.',
              description: "The blog has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            navigate("/blogs")
          } 
          // else {
          //   await editBlog(id,formDatab);
          //   navigate("/blogs")
    
          //   toast({
          //     title: 'Blog Added Successfully.',
          //     description: "The blog has been added successfully.",
          //     status: 'success',
          //     duration: 5000,
          //     isClosable: true,
          //   });
          // }          
          setSelectedBlog(null);
        } catch (error) {
          if(selectedBlog){
          toast({
            title: 'Error',
            description: "There was an error updating the blog.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }else{
          toast({
            title: 'Error',
            description: "There was an error adding the blog.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        }
      };
      const tagOptions = tags.map(tag => ({
        value: tag.id,
        label: tag.name
      }));
    const handleChange = async(e) => {
      const { name, value, files } = e.target;
      if (files) {
        const file = e.target.files[0];
        
          const maxSize = 2 * 1024 * 1024; // 2MB in bytes        
          
          if (file.size > maxSize) {
              e.target.value="";
            return toast({
              title: "An error occurred.",
              description: "File size should not exceed 2MB.",
              status: "error", // "error" for error message
              duration: 5000,  // Duration in milliseconds
              isClosable: true, // Option to close the toast
            });     
          }
        }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        if (name==="name") {
          const uniqueUrl = await generateUniqueUrl(value, blogsUrl);
            setFormData((prevData) => ({
              ...prevData,
              url: "https://bacr.com.pk/blog-detail/"+uniqueUrl, // Auto-generate the URL slug
            }
          ));
          }
        
      };
      const handleEmailChange = (selectedOptions) => {
        if (!selectedOptions) return;
      
        // Extract email strings if selectedOptions contains objects
        const emailArray = Array.isArray(selectedOptions)
          ? selectedOptions.map((option) => (typeof option === "object" ? option.value : option))
          : selectedOptions.split(",").map((email) => email.trim());
      
        // Validate all emails
        const invalidEmails = emailArray.filter((email) => !validateEmail(email));
      
        if (invalidEmails.length > 0) {
          console.error(`Invalid emails: ${invalidEmails.join(", ")}`);
          alert(`Invalid emails: ${invalidEmails.join(", ")}`);
          return; // Exit if there are invalid emails
        }
      
        // Set valid emails
        setEmails(selectedOptions);
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <Heading textAlign={"center"}>EDIT BLOG</Heading>
        <FormControl mt={4} isRequired>
              <FormLabel>Title</FormLabel>
              <Input name="name" value={formData?.name} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>URL Slug</FormLabel>
            <Input name="url" value={formData?.url} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mb={4}>
              <FormLabel>Category</FormLabel>
              <Select name="categoryId" value={formData.categoryId} onChange={handleChange} placeholder="Select Category">
              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Tags</FormLabel>  
               <MultiSelect
              options={tagOptions}
              value={selectedTags}
              onChange={handleTagChange}
              labelledBy="Select"
            />
          </FormControl>
              {/* Multi-Email Input */}
      <FormControl mt={4}>
        <FormLabel>Emails</FormLabel>
        {/* <Input type="file" name="emails" onChange={(e) => setFormData({ ...formData, emails: e.target.files[0] })} /> */}
        <CreatableSelect
              isMulti
              value={emails}
              onChange={handleEmailChange}
              options={[]}
              placeholder="Add or enter emails"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
      </FormControl>
       {/* Buttons for downloading Excel and PDF files */}
       {/* <Button mt={4} colorScheme="blue" onClick={downloadExcel}>
        Download Excel
      </Button>

      <Button mt={4} colorScheme="green" onClick={downloadPdf}>
        Download PDF
      </Button> */}
            <FormControl mt={4} isRequired>
              <FormLabel>Content</FormLabel>
              {/* <button type="button" onClick={handleClearButtonClick}>Add Clear</button>  */}

              <ReactQuill
               ref={quillRef} 
              theme="snow"
              name="description"
              value={formData?.description}
              onChange={handleDescriptionChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  [{ align: [] }],
                  ['addClear'],
                  ["clean"], // Remove formatting
                ],
                // handlers: {
                //   addClear: function () {
                //     const range = this.quill.getSelection();
                //     if (range) {
                //       this.quill.insertEmbed(range.index, 'clear', true);
                //     }
                //   },
                // },
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />

            </FormControl>
            <FormControl mt={4}>
              <HStack justifyContent="space-between">
                <FormLabel>Feature Image</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Max File Size 2MB)
                </Box>
                </HStack>
              <Input type="file" name="image" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Alt Text</FormLabel>
            <Input
              type="text"
              name="alt_text"
              placeholder="Enter alt text for the image"
              value={formData?.alt_text || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Image Caption</FormLabel>
            <Textarea
              name="caption_img"
              placeholder="Enter a caption for the image"
              value={formData?.caption_img || ''}
              onChange={handleChange}
            />
          </FormControl>
            <FormControl mt={4}>
              <FormLabel>Meta Title</FormLabel>
              <Input name="meta_title" value={formData?.meta_title} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Meta Description</FormLabel>
              <Textarea name="meta_desc" value={formData?.meta_desc} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Focus Keywords</FormLabel>
              {/* <Input name="focus_key" value={focus_key} onChange={handleChange} /> */}
              <CreatableSelect
              isMulti
              value={focusKeys}
              onChange={(selectedOptions) => setfocusKeys(selectedOptions)}
              options={[]}
              placeholder="Add or enter focus keys"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Social Media Tags</FormLabel>
              <CreatableSelect
              isMulti
              value={socTags}
              onChange={(selectedOptions) => setsocTags(selectedOptions)}
              options={[]}
              placeholder="Add or enter social media tags"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Canonical URL</FormLabel>
            <Input name="can_url" value={formData?.can_url} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mb={4}>
              <FormLabel>Status</FormLabel>
              <Select name="status" value={formData.status} onChange={handleChange} placeholder="Select Status">
                <option value="draft">Draft</option>
                <option value="publish">Publish Now</option>
                <option value="schedule">Schedule Post</option>
              </Select>
          </FormControl>
                {formData.status === "schedule" && (
                <FormControl isRequired mb={4}>
                  <FormLabel>Schedule Time</FormLabel>
                  <Input
                    type="datetime-local"
                    name="schedule_time"
                    value={formData.schedule_time ? formData.schedule_time.slice(0, 16) : ""} 
                    onChange={handleChange}
                  />
                </FormControl>
                )}
              <FormControl mt={4}>
              <FormLabel>Excerpt</FormLabel>
              <Textarea name="excerpt" value={formData?.excerpt} onChange={handleChange} />
              </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Edit Blog
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditBlog;