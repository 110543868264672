import React, { useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, useDisclosure,useToast, 
  Textarea,
  Select,
  Flex,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill theme CSS
import {fetchBlogsAll,createBlog,editBlog,deleteBlog,fetchBlogCategories,fetchTags} from "../../api";
import { useSelector } from "react-redux";

function BlogTable() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [emails, setEmails] = useState([]);
  const [focusKeys, setfocusKeys] = useState([]);
  const [socTags, setsocTags] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description:"",
    image:"",
    meta_desc:"",
    meta_title:"",
    caption_img:"",
    alt_text:"",
    can_url:"",
    excerpt:"",
    status:"",
    schedule_time:""
  });
  const toast = useToast();
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const cancelRef = useRef();    
  const openDeleteDialog = (projectId) => {
    setBlogToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setBlogToDelete(null);
    setIsDeleteDialogOpen(false);
  };
  const fetchBlogsb = async () => {
    try {
      const response = await fetchBlogsAll();
      setBlogs(response);
      const responseb = await fetchBlogCategories();
      setCategories(responseb);
      const responsec = await fetchTags();
      setTags(responsec);
      
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
  // console.log(tags[0].name);
  // Fetch all blogs
  useEffect(() => {
    fetchBlogsb();
  },[]);
  

  const totalPages = Math.ceil(blogs.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };
  
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, '-')         // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '')     // Remove all non-word characters (except hyphens)
      .replace(/\-\-+/g, '-')       // Replace multiple hyphens with a single one
      .replace(/^-+/, '')           // Remove hyphen at the start of the string
      .replace(/-+$/, '');          // Remove hyphen at the end of the string
  };
  

  // Handle project update submission
  

  // Delete project
  const handledeleteBlog = async (id) => {
    try {
      if (!blogToDelete) return;
      await deleteBlog(blogToDelete);
      closeDeleteDialog();
      fetchBlogsb();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/add-blog">Add Blog</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Image</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((blog,index) => (
            <Tr key={blog.id}>
              <Td>{(currentPage - 1) * itemsPerPage + index + 1}</Td>
              <Td>{blog.name}</Td>
              <Td>
                <img src={`${blog.imagePath}`} alt={blog.name} width="50" />
              </Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/edit-blog/${blog.url}`}>Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => openDeleteDialog(blog.id)}>Delete</Button>
                )}
                <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink}   to={`/blog/${blog.url}`}>View</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this blog? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>

              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handledeleteBlog} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default BlogTable;