import { 
  Box, 
  Flex, 
  VStack, 
  Button, 
  Avatar, 
  Text, 
  useToast, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  useDisclosure, 
  IconButton, 
  Drawer, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerHeader, 
  DrawerBody 
} from '@chakra-ui/react';
// import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate,useLocation } from 'react-router-dom';
import '../App.css';
import { login, logout } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode'; // Correct the import
import { useEffect } from 'react';

const AdminSidebar = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const getButtonStyle = (path) => ({
    variant: 'ghost',
    colorScheme: isActive(path) ? undefined : 'whiteAlpha',
    bg: isActive(path) ? 'white' : 'transparent',
    color: isActive(path) ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'inherit',
  });
  const handleLogout = () => {
    localStorage.removeItem('token');
    toast({
      title: 'Logged out successfully.',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
    dispatch(logout());
    navigate('/login');
  };
console.log("user "+user.roles);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      dispatch(login({ token, user: decodedToken }));
    }
  }, [dispatch]);

  const SidebarContent = () => (
    <VStack align="stretch" spacing={4} gap="4px" alignItems={"center"} position={"fixed"} width={"210px"}>
    <Box textAlign="center" mb={2}>
      <Avatar name={isLoggedIn ? user.username : 'Admin'} size="lg" />
      <Text mt={0} mb={0} fontWeight="bold">
        {isLoggedIn ? user.username : 'Welcome'}
      </Text>
    </Box>

    {isLoggedIn ? (
      <>
        <Button mt={0} as={RouterLink} to="/teams" {...getButtonStyle('/teams')}>
          Teams
        </Button>
        <Button as={RouterLink} to="/certificates" {...getButtonStyle('/certificates')}>
          Certificates
        </Button>
        <Button as={RouterLink} to="/clients" {...getButtonStyle('/clients')}>
        Clients
        </Button>
        <Button as={RouterLink} to="/projects" {...getButtonStyle('/projects')}>
          Projects
        </Button>
        <Button as={RouterLink} to="/jobs" {...getButtonStyle('/jobs')}>
        Jobs
        </Button>
        <Button as={RouterLink} to="/brands" {...getButtonStyle('/brands')}>
        Brands
        </Button>
        <Button as={RouterLink} to="/product" {...getButtonStyle('/product')}>
          Products
        </Button>
        <Button as={RouterLink} to="/blog" {...getButtonStyle('/blog')}>
          Blogs
        </Button>
        <Button as={RouterLink} to="/contacts" {...getButtonStyle('/contacts')}>
        Contacts
        </Button>
        {user?.roles==="superadmin" && 
        <Button as={RouterLink} to="/users" {...getButtonStyle('/users')}>
        User Roles
        </Button>
        }
      </>
    ) : (
      <>
        <Button as={RouterLink} to="/login" {...getButtonStyle('/login')}>
          Login
        </Button>
      </>
    )}

    {isLoggedIn && (
      <Button onClick={handleLogout} variant="ghost" colorScheme="whiteAlpha">
        Logout
      </Button>
    )}
  </VStack>
  );

  return (
    <>
      {/* Mobile Hamburger Menu */}
      <Box display={{ base: 'block', md: 'none' }} p={4}>
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold" color="rgb(0 70 113 / var(--tw-bg-opacity, 1))">
            Admin Panel
          </Text>
          <IconButton
            // icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Toggle Sidebar"
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>
      </Box>

      {/* Sidebar for Desktop */}
      <Box
      // position={"fixed"}
        w="250px"
        bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))"
        color="white"
        p={4}
        display={{ base: 'none', md: 'block' }}
      >
        <SidebarContent />
      </Box>

      {/* Drawer for Mobile */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color="white" textAlign="center">
            Admin Panel
          </DrawerHeader>
          <DrawerBody>
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
