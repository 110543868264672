import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,    
    VStack,
    Heading,
  } from "@chakra-ui/react";
import { createSocialLink, fetchSocialLinks} from "../../api";
import { useNavigate } from "react-router-dom";

const AddContact = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const [formData, setFormData] = useState({
      whatsappno: "",
      telephone1: "",
      telephone2: "",
      email:""
      });

    useEffect(() => {
            fetchProdBrand();
          }, []);
          const fetchProdBrand=async()=>{
            const res=await fetchSocialLinks();
            setFormData({
            whatsappno:res[0].whatsappno,
            telephone1:res[0].telephone1,
            telephone2:res[0].telephone2,
            email:res[0].email,
              });
          }
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        try {
            await createSocialLink(formData);    
            navigate("/contacts")
            toast({
              title: 'Contacts Added Successfully.',
              description: "The contacts has been added successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          
          setFormData({ name: ""});
    
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error adding the contact.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>SAVE CONTACT</Heading>
            <FormControl mt={4}>
              <FormLabel>Telephone No 1:</FormLabel>
              <Input type="text" name="telephone1" value={formData.telephone1} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Telephone No 2:</FormLabel>
              <Input type="text" name="telephone2" value={formData.telephone2} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Whatsapp No:</FormLabel>
              <Input type="text" name="whatsappno" value={formData.whatsappno} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email:</FormLabel>
              <Input type="email" name="email" value={formData.email} onChange={handleChange} />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Save Contact
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default AddContact;