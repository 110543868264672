import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Select, Button, Stack, Checkbox, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { addModulesToRole, fetchModules, fetchRoleById, fetchRoles } from '../../api';

const RoleManagement = () => {
  const navigate=useNavigate();
  const [modules, setModules] = useState([]);  // List of modules to display
  const [selectedModules, setSelectedModules] = useState([]);  // Selected modules
  const [roles, setRoles] = useState([]);  // List of roles to assign modules to
  const [selectedRole, setSelectedRole] = useState("");  // Selected role
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    // Fetch available roles and modules (you could replace this with API calls)
    const fetchRolesAndModules = async () => {
      try {
        const roleResponse = await fetchRoles();
        
        const moduleResponse = await fetchModules();
        
        setRoles(roleResponse ? roleResponse:[] );
        setModules(moduleResponse ? moduleResponse:[]);
      } catch (error) {
        console.error('Error fetching roles and modules:', error);
      }
    };

    fetchRolesAndModules();
  }, []);
  const handleChange=async(e)=>{
    const roleId = e.target.value;
    setSelectedRole(roleId?roleId:"");

    // Fetch role details, including associated modules
    if (roleId) {
      // Fetch role details, including associated modules
      const selroles = await fetchRoleById(roleId);
      
      if (selroles && selroles.modules) {
        // Set the selected modules based on the fetched role
        setSelectedModules(selroles?.modules ? selroles?.modules : []);
      } else {
        setSelectedModules([]); // Clear selection if no modules are associated
      }
    }

  }


  const handleSubmit = async () => {
    setLoading(true);

    try {
      await addModulesToRole(selectedRole,selectedModules)

      // await axios.put(`https://bacr2024-backend-production.up.railway.app/api/role/${selectedRole}/add-modules`, {
      //   moduleIds: selectedModules,
      // });
      alert('Modules added successfully!');
      navigate("/users");
    } catch (error) {
      console.error('Error assigning modules to role:', error);
      alert('Failed to assign modules');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    {/* <Button
        bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
        as={RouterLink}
         to="/assign-role"
        isFullWidth
        mt={4}
      >
        Assign Role
      </Button> */}
    <Box maxW="md" mx="auto" p={4} boxShadow="lg" borderRadius="md">

      
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>

      {/* <Box display={"block"}> */}
      <FormControl mb={4}>
        <FormLabel>Select Role</FormLabel>
        <Select
          placeholder="Select a role"
          value={selectedRole}
          onChange={handleChange}
        >
          {roles?.map((role) => (
            <option key={role?.id} value={role?.id}>
              {role?.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>Select Modules</FormLabel>
        <Stack spacing={4}>
          {modules?.map((module) => (
            <Checkbox
              key={module?.id}
              value={module?.id}
              isChecked={selectedModules.includes(module.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedModules([...selectedModules, module.id]);
                } else {
                  setSelectedModules(selectedModules.filter(id => id !== module.id));
                }
              }}
            >
              {module?.name}
            </Checkbox>
          ))}
        </Stack>
      </FormControl>

      <Button
        bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
        isLoading={loading}
        onClick={handleSubmit}
        isFullWidth
        mt={4}
      >
        Assign Modules
      </Button>
      </VStack>
    </Box>
    </>
  );
};

export default RoleManagement;