import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { fetchProductBrand } from "../../api";

const Brands = () => {
    const navigate = useNavigate();
    const {filter} = useParams();
    const [brands, setBrands] = useState([]);  
    const [filters, setFilters] = useState("");  
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const itemsPerPage = 10;
    const fetchBrands = async () => {
      try {
        const response = await fetchProductBrand(filter);
        setBrands(response);
        
        if(filter==="new"){
          setFilters("New Product");
        }else if(filter==="used"){
          setFilters("Used Product");
        }else if(filter==="spare"){
          setFilters("Spare Parts");
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    
    const totalPages = Math.ceil(brands.length / itemsPerPage);

    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = brands.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageClick = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) setCurrentPage((prev) => prev - 1);
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
    };
  
    // Generate page numbers
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    useEffect(() => {
      fetchBrands();
    }, []);  
  return (
    <Box p={5}>
      <Heading>{filters} Brands</Heading>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={`/categories`} mt={4}>Product Category</Button>
       <Table mt={4}>
        <Thead>
          <Tr>
            <Th>S.No</Th>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((brand,index) => (
            <Tr key={brand.id}>
              <Td>{index + 1}</Td>
              <Td>{brand.name}</Td>
              <Td> 
               <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink}   to={`/add-product/${brand.id}`}>Add Product</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </Box>
  )
}
export default Brands;