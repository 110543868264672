import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
  } from "@chakra-ui/react";
import { createCertificate, fetchCertificateSrno} from "../../api";
import { useNavigate } from "react-router-dom";

const AddCertificate = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const [srno, setSrNo] = useState();
    const [formData, setFormData] = useState({
        sr_no: '',
        name: '',
        issuedBy: '',
        issuedDate: '',      
        certificateFile: '',
      });
    useEffect(() => {
      fetchSrno();
    }, [srno]);
    
    const fetchSrno=async()=>{
      const resp=await fetchCertificateSrno();
      setSrNo(resp[0]?.sr_no);
      setFormData((prevData) => ({
        ...prevData,
        sr_no: srno + 1,
      }));
      
    }
   

      const handleAddSubmit = async (e) => {
        e.preventDefault();
        const srnob=srno+1;
        if(formData.sr_no > srnob){
          toast({
            title: 'Error',
            description: "SR No cannot be greater than "+ srnob,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return; 
        }
    if(formData.name =='' || formData.sr_no =='' || formData.issuedDate =='' || formData.issuedBy =='' || formData.certificateFile ==''){
      return toast({
        title: "An error occurred.",
        description: "All fields are required",
        status: "error", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });     
    }
    try {
      const formDatab = new FormData();
      // Append form data
      formDatab.append('name', formData.name);
      formDatab.append('sr_no', formData.sr_no);
      formDatab.append('issuedDate', formData.issuedDate);
      formDatab.append('issuedBy', formData.issuedBy);
      formDatab.append('image', formData.certificateFile);

      await createCertificate(formDatab);
      navigate("/certificates")
      toast({
        title: "Action Successful!",
        description: "Certificate Successfully Added",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });
    } catch (error) {
      if (error.status === 500) { 
        return toast({
           title: 'Error',
           description: "File size exceeds 2MB limit.",
           status: 'error',
           duration: 5000,
           isClosable: true,
         });  
        }
      console.error('Error submitting certificate:', error);
    }
      };
    
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (files) {
        const file = e.target.files[0];
        
          const maxSize = 2 * 1024 * 1024; // 2MB in bytes
          console.log(file.size);
          console.log(maxSize);
          
          if (file.size > maxSize) {
              e.target.value="";
            return toast({
              title: "An error occurred.",
              description: "File size should not exceed 2MB.",
              status: "error", // "error" for error message
              duration: 5000,  // Duration in milliseconds
              isClosable: true, // Option to close the toast
            });     
          }
        }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));    
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <Heading textAlign={"center"}>ADD CERTIFICATE</Heading>
        <FormControl isRequired>
        <HStack justifyContent="space-between">
        <FormLabel>SR No</FormLabel>
        <Box as="span" fontSize="sm" color="gray.500">
          (Last SR NO {srno})
        </Box>
      </HStack>
              <Input name="sr_no" value={formData?.sr_no ? formData?.sr_no : ""} onChange={handleChange} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input isRequired
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Issued By</FormLabel>
              <Input
                name="issuedBy"
                value={formData.issuedBy}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Issued Date</FormLabel>
              <Input
                name="issuedDate"
                type="date"
                value={formData.issuedDate}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
            <HStack justifyContent="space-between">
                <FormLabel>Certificate File</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Max File Size 2MB File Type jpeg | jpg | png)
                </Box>
                </HStack>
              <Input
                name="certificateFile"
                type="file"
                onChange={(e) => setFormData({ ...formData, certificateFile: e.target.files[0] })}
              />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Certificate
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default AddCertificate;