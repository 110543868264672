import React, { useState, useEffect } from "react";
import {
    Box,Button,Input,FormControl,FormLabel,
    HStack,
    useToast,
    VStack,
    Heading,
  } from "@chakra-ui/react";
import { editCertificate, fetchCertificateById, fetchCertificateSrno} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditCertificate = () => {
    const navigate=useNavigate();
    const { id } = useParams(); 
    const toast=useToast();    
    const [srno, setSrNo] = useState();
    const location = useLocation();
    const [formData, setFormData] = useState({
        sr_no: '',
        name: '',
        issuedBy: '',
        issuedDate: '',
        certificateFile: '',
      });
    useEffect(() => {
      fetchSrno();
      fetchCertificatesb();
    }, [srno]);
    useEffect(() => {
      // Check if the current path is "/edit-blog/:id"
      if (location.pathname.startsWith("/edit-certificate/")) {
        // Prevent the user from going back to the previous page
        window.history.pushState(null, null, window.location.href);  // Add a new history entry
        window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
  
        // Handle the back/forward navigation event
        const handlePopState = () => {
          navigate("/certificates");  // Redirect to /blogs page if back is triggered
        };
  
        // Set the popstate event handler
        window.addEventListener("popstate", handlePopState);
  
        // Cleanup the event listener when the component unmounts or the path changes
        return () => {
          window.removeEventListener("popstate", handlePopState);
        };
      }
  
      // Cleanup for other routes (this does nothing for non-edit pages)
      return () => {};
    }, [navigate, location.pathname]);
    const fetchCertificatesb = async () => {
        try {
          const data = await fetchCertificateById(id);
          setFormData({
            sr_no: data.sr_no,
            name: data.name,
            issuedBy: data.issuedBy,
            issuedDate: data.issuedDate ? new Date(data.issuedDate).toISOString().slice(0, 10) : '',
            certificateFile: data.certificateFile,
          });
        } catch (error) {
          console.error('Error fetching certificates:', error);
        }
      };
    const fetchSrno=async()=>{
      const resp=await fetchCertificateSrno();
      setSrNo(resp[0].sr_no);
    }

      const handleAddSubmit = async (e) => {
        e.preventDefault();
        if(formData.sr_no > srno){
          toast({
            title: 'Error',
            description: "SR No cannot be greater than "+ srno,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return; 
        }
    if(formData.name =='' || formData.sr_no =='' || formData.issuedDate =='' || formData.issuedBy ==''){
      return toast({
        title: "An error occurred.",
        description: "All fields are required",
        status: "error", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });     
    }
    try {
      const formDatab = new FormData();
      // Append form data
      formDatab.append('name', formData.name);
      formDatab.append('sr_no', formData.sr_no);
      formDatab.append('issuedDate', formData.issuedDate);
      formDatab.append('issuedBy', formData.issuedBy);
      if(formData?.certificateFile){
      formDatab.append('image', formData?.certificateFile);
    }
      await editCertificate(id,formDatab);
      navigate("/certificates")
      toast({
        title: "Action Successful!",
        description: "Certificate Successfully Updated",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });
    } catch (error) {
      if (error.status === 500) { 
        return toast({
           title: 'Error',
           description: "File size exceeds 2MB limit.",
           status: 'error',
           duration: 5000,
           isClosable: true,
         });  
        }
      console.error('Error submitting certificate:', error);
    }
      };
    
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <Heading textAlign={"center"}>EDIT CERTIFICATE</Heading>
        <FormControl>
        <HStack justifyContent="space-between">
        <FormLabel>SR No</FormLabel>
        <Box as="span" fontSize="sm" color="gray.500">
          (Last SR NO {srno})
        </Box>
      </HStack>
              <Input name="sr_no" value={formData.sr_no} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Issued By</FormLabel>
              <Input
                name="issuedBy"
                value={formData.issuedBy}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Issued Date</FormLabel>
              <Input
                name="issuedDate"
                type="date"
                value={formData.issuedDate}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
            <HStack justifyContent="space-between">
                <FormLabel>Certificate File</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Max File Size 2MB)
                </Box>
                </HStack>
              <Input
                name="certificateFile"
                type="file"
                onChange={(e) => setFormData({ ...formData, certificateFile: e.target.files[0] })}
              />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Certificate
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditCertificate;