import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
    Select,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {fetchProductSno, createProduct, fetchCategories} from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import CategorySelect from "./CategorySelect";
import MyImageUploader from "../Common/MyImageUploader";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const AddProduct = () => {
    const navigate=useNavigate();
  const [categories, setCategories] = useState([]);
  const {brandId,filter}=useParams();
  const [srno, setSno] = useState([]);
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const toast=useToast();
  const [formData, setFormData] = useState({
    sr_no:"",
    part:"",
    sr_nop:"",
    name: "",
    model: "",               
    aval: "",           
    price: "",          
    quantity: "",          
    logo:"",
    detail: "",          
    categoryId: "",
    capacity:""          
  });
  const handleImagesSelect = (selectedImages, selectedMainImage) => {
    setImages(selectedImages);
    setMainImage(selectedMainImage);
  };

  const [showPrice, setShowPrice] = useState(false);

  const addCategory = async (newCategoryName) => {
      // Update local state
      setCategories([...categories, {
        name:newCategoryName,
        _id:newCategoryName
      }]);
  };

    const fetchProductsb = async () => {
        try {
          const responseb = await fetchCategories();
          setCategories(responseb);
          const responsed = await fetchProductSno(filter);
          
          setSno(responsed.sr_no);
          setFormData((prevData) => ({
            ...prevData,
            sr_no: responsed.sr_no + 1,
          }));
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      useEffect(() => {
        fetchProductsb();
      }, []);
      const handleCategoryChange = (category) => {
        
        setFormData((prev) => ({
          ...prev,
          categoryId: category,
        }));
      };
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          detail: content,
        }));
      };
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        const sno=srno+1;
        if(formData.sr_no > sno){
          toast({
            title: 'Error',
            description: "SR No cannot be greater than "+ sno,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return; 
        }
        
        
  if (formData.sr_no==="" ||formData.sr_nop==="" ||formData.name=== "" || formData.categoryId ==="" ) {
    // Join the empty field names into a readable string
    const emptyFieldsMessage = `Please fill in all the required fields.`;
    toast({
      title: 'Error',
      description: emptyFieldsMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return; 
  }

    const formDataToSend = new FormData();
    formDataToSend.append("sr_no", formData.sr_no);
    formDataToSend.append("sr_nop", formData.sr_nop);
    formDataToSend.append("part", formData.part);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("model", formData.model);
    images.forEach(image => formDataToSend.append("images", image));
    formDataToSend.append("image", mainImage);
    formDataToSend.append("logo", formData.detail);
    formDataToSend.append("aval", formData.aval);
    if(showPrice) formDataToSend.append("price", formData.price);
    formDataToSend.append("quantity", formData.quantity);
    formDataToSend.append("categoryId", formData.categoryId.value);
    formDataToSend.append("brandId", brandId);
    formDataToSend.append("filter", filter);
    formDataToSend.append("detail", formData.detail);
    formDataToSend.append("capacity", formData.capacity);

    try {
        await createProduct(formDataToSend);
      
      setFormData({ name: "", image: null });
      toast({
        title: 'Product Added Successfully.',
        description: "The product has been updated successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate("/product");
    } catch (error) {
      console.error("Error adding client:", error);
    }
      };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }}
    mx="auto"
    p={4}
    bg="gray.50"
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
      <FormControl isRequired mb={4}>
      <HStack justifyContent="space-between">
        <FormLabel>SR No</FormLabel>
        <Box as="span" fontSize="sm" color="gray.500">
          (Last SR NO {srno})
        </Box>
      </HStack>
                <Input type="text" name="sr_no" value={formData.sr_no} onChange={handleChange} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Serial No of product</FormLabel>
                <Input type="text" name="sr_nop" value={formData.sr_nop} onChange={handleChange} />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formData.name} onChange={handleChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Part Number</FormLabel>
                <Input type="text" name="part" value={formData.part} onChange={handleChange} />
              </FormControl>             
              <FormControl mb={4}>
                <FormLabel>Model Number</FormLabel>
                <Input type="text" name="model" value={formData.model} onChange={handleChange} />
              </FormControl>             
              <FormControl isRequired mb={4}>
              <FormLabel>Availability</FormLabel>
              <Select name="aval" value={formData.aval} onChange={handleChange} placeholder="Select availability">
                <option value="instock">In Stock</option>
                <option value="outofstock">Out of Stock</option>
                <option value="inorder">Pre-Order</option>
              </Select>
            </FormControl>
              <FormControl mb={4} isRequired>
              <CategorySelect
              categories={categories}
              onCategoryChange={handleCategoryChange}
              onAddCategory={addCategory}
              selected={formData.categoryId}
            />
              {/* <Select name="categoryId" value={formData.categoryId} onChange={handleChange} placeholder="Select Category">
              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
              </Select> */}
            </FormControl>
            <FormControl display="flex" alignItems="center" mb={4}>
              <FormLabel htmlFor="show-price" mb="0">
                On Call
              </FormLabel>
              <Switch
                id="show-price"
                isChecked={showPrice}
                onChange={() => setShowPrice((prev) => !prev)}
              />
            </FormControl>

            {!showPrice && (
              <FormControl isRequired mb={4}>
                <FormLabel>Price</FormLabel>
                <InputGroup>
                <Input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="Enter price"
                />
                <InputRightAddon children="PKR" />
              </InputGroup>
              </FormControl>
            )}
          {/* </Box> */}
              <FormControl mb={4}>
                <FormLabel>Capacity</FormLabel>
                <InputGroup>
                <Input
                  type="number"
                  name="capacity"
                  value={formData.capacity}
                  onChange={handleChange}
                  placeholder="Enter Capacity"
                />
                <InputRightAddon children="TON" />

              </InputGroup>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Quantity</FormLabel>
                <Input
                  type="number"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  placeholder="Enter Quantity"
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Detail</FormLabel>
                <ReactQuill
                theme="snow"
                  maxLength={250}
                  value={formData.detail}
                  onChange={handleDescriptionChange}
                  placeholder="Write your content here..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                      ["bold", "italic", "underline", "strike"], // Text styles
                      [{ list: "ordered" }, { list: "bullet" }], // Lists
                      ["link", "image", "video"], // Links, Images, Videos
                      [{ align: [] }],
                      ["clean"], // Remove formatting
                    ],
                    imageResize: {
                      modules: ['Resize', 'DisplaySize'],
                    },
                  }}
                />                 
              </FormControl>
              
              <FormControl mb={4} isRequired>
                <FormLabel>Logo</FormLabel>
                <Input
                  type="file"
                  name="logo"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                {/* <Input type="file" name="image" onChange={handleChange} accept="image/*" /> */}
                <MyImageUploader onImagesSelect={handleImagesSelect} />
                {/* Display the selected images and main image */}
          {images.length > 0 && (
            <Box mt={4}>
              <Text fontSize="lg" mb={2}>
                Selected Images:
              </Text>
              <Box display="flex" flexWrap="wrap" justifyContent={"space-evenly"}>
                {images.map((image, index) => (
                  <Box key={index} width="150px" mb={4}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected Image ${index}`}
                        width="100%"
                        style={{ objectFit: "cover", borderRadius: "8px" }}
                      />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}

          {mainImage && (
            <Box display="flex"  flexDirection={"column"} alignItems="center" mt={4}>
              <Text fontSize="lg" mb={2}>
                Main Image:
              </Text>
              <Box width="300px">
                <img
                  src={URL.createObjectURL(mainImage)}
                  alt="Main Image"
                  width="100%"
                  style={{ objectFit: "cover", borderRadius: "8px" }}
                />
              </Box>
            </Box>
          )}
              </FormControl>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Product
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddProduct