import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
    Text,
    Select,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { createCareer, fetchDepartments} from "../../api";
import { useNavigate } from "react-router-dom";

const AddCareer = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const [formData, setFormData] = useState({
        name: "",
        email:"",
        phone:"",
        department:"",
        resume:""
      });  
    const [departs, setDeparts] = useState([]);  

      const fetchDepartb = async () => {
        try {
          const responseb = await fetchDepartments();
          setDeparts(responseb);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      useEffect(() => {
        fetchDepartb();
      }, []);

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        // const isFormInvalid = Object.values(formData).some(value => !value);
        const requiredFields = ["name"];
        const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `All fields are required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("name", formData.name);
            formDataToSend.append("email", formData.email);
            formDataToSend.append("phone", formData.phone);
            formDataToSend.append("department", formData.department);
            formDataToSend.append("resume", formData.resume);
            await createCareer(formDataToSend);    
            navigate("/careers")
            toast({
              title: 'Careers Added Successfully.',
              description: "The careers has been added successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
    
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error adding the career.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
      
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>ADD CAREER</Heading>
     <FormControl mt={4} isRequired>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
     <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input name="email" value={formData.email} onChange={handleChange} />
            </FormControl>
     <FormControl mt={4}>
              <FormLabel>Phone</FormLabel>
              <Input name="phone" value={formData.phone} onChange={handleChange} />
            </FormControl>
            <FormControl>
          <FormLabel>Department</FormLabel>
          <Select name="department" value={formData.department} onChange={handleChange} placeholder="Select Department">
              {departs.map((depart) => (
                <option value={depart.id}>{depart.name}</option>
              ))}
              </Select>
        </FormControl>
            <FormControl>
          <FormLabel>Resume</FormLabel>
          <Input type="file" name="resume" onChange={handleChange} />
        </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Career
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default AddCareer;