import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
  useToast,
  Flex,
  Card,
  CardBody,
  Stack,
  Image,
  Heading,
  Text,
  CardFooter,
  ButtonGroup,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { createProduct, fetchProducts,editProduct,deleteProduct,fetchCategories } from "../../api";
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from "react-redux";

const ProductPage = ({products,brand,addpro,fetchProductsc}) => {
  const toast=useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const { user } = useSelector((state) => state.auth);
  const [editProductId, setEditProductId] = useState(null);
  const [showPrice, setShowPrice] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const cancelRef = useRef();
  const [formData, setFormData] = useState({
    sr_no:"",
    sr_nop:"",
    name: "",
    model: "",          
    image: null,        
    aval: "",           
    price: "",          
    detail: "",          
    categoryId: ""          
  });  
  

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  

  const totalPages = Math.ceil(products?.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products?.slice(indexOfFirstItem, indexOfLastItem);
  const openDeleteDialog = (projectId) => {
    setProductToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setProductToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  // Handle delete button click
  const handleDelete = async (productId) => {
    try {
      if (!productToDelete) return;
      await deleteProduct(productToDelete);
      closeDeleteDialog();
      toast({
        title: 'Product Deleted Successfully.',
        description: "The product has been deleted successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchProductsc();
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  return (
    <Box p={5}>      
      <Table mt={4}>
        <Thead>
          <Tr>
            <Th>S.No</Th>
            <Th>Name</Th>
            <Th>Image</Th>
            <Th>Model</Th>
            <Th>Price</Th>
            <Th>Avalability</Th>
            {/* <Th>Quantity</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems?.map((product,index) => (
            <Tr key={product.id}>
              <Td>{(currentPage - 1) * itemsPerPage + index + 1}</Td>
              <Td>{product.name}</Td>
              <Td>
                <img src={`${product.imagePath}`} alt={product.name} width="50" />
              </Td>
              <Td>{product.model}</Td>
              <Td>{product.price}</Td>
              <Td>{product.aval}</Td>
              {/* <Td>{product.quantity}</Td> */}
              <Td>
                {addpro &&
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}  as={RouterLink} to={`/edit-product/${brand}/${product.id}`}>
                  Edit
                </Button>
                }
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" ml={2} onClick={() => openDeleteDialog(product.id)}>
                  Delete
                </Button>
                )}
                <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink}   to={`/product/${product.id}`}>View</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
            >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this product? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ProductPage;