import React, { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";

const CategorySelect = ({ categories, onAddCategory, onCategoryChange,selected }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  // Transform categories prop into options for the select component
  useEffect(() => {
    
    if (categories && categories.length > 0) {
      const transformedOptions = categories.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setOptions(transformedOptions);
      const preselected = transformedOptions.find((option) => option.value === selected);
      if (preselected) {

        setSelectedOption(preselected);
      }
    }
  }, [categories]);
// console.log(selected);

    // Handle creating a new category
  const handleCreateCategory = (inputValue) => {
    if (inputValue) {
      const newCategory = {
        value: inputValue,
        label: inputValue,
      };

      setOptions((prevOptions) => [...prevOptions, newCategory]);
      onAddCategory(inputValue); // Notify parent about the new category
      setSelectedOption(newCategory); // Automatically select the new category
      onCategoryChange(inputValue); // Notify parent about the selection
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onCategoryChange(selectedOption); // Notify parent about the selection
  };

  return (
    <FormControl isRequired mb={4}>
      <FormLabel >Category</FormLabel>
      <CreatableSelect
        options={options}
        value={selectedOption}
        onInputChange={(value) => setInputValue(value)}
        inputValue={inputValue}
        onChange={handleChange}
        placeholder="Search or Add Category"
        isClearable
        isSearchable
        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
        allowCreateWhileLoading={false}
        createOptionPosition="first"
        onCreateOption={handleCreateCategory}
      />
    </FormControl>
  );
};

export default CategorySelect;
