import { Box, Flex } from '@chakra-ui/react';
import AdminNavbar from './AdminNavbar';

const DashboardLayout = ({ children }) => {
  return (
    <Flex h="100%" minH={"100vh"}>
      <AdminNavbar />
      <Box flex="1" bg="gray.50" p={8}>
      <Box p={8}>{children}</Box>
      </Box>
    </Flex>
  );
};
export default DashboardLayout;