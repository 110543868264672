import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { fetchProductById } from "../../api";
import DOMPurify from 'dompurify';
import ContentSection from "../Common/ContentSection";

const ProductView = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetchProductById(id);
        if (response) {
        setProduct(response.product);
        console.log(response.product);
        
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);  

 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Image src={product?.imagePath} alt={product?.alt_text} borderRadius="md" mb={4} />
      <Heading as="h1" mb={4}>x
        {product?.name}
      </Heading>     
      
          {product?.sr_no && (
           <ContentSection title={"SR NO"} desc={product?.sr_no} />
           )}
          {product?.model && (
           <ContentSection title={"Model"} desc={product?.model} />
           )}
           
           {product?.price !==0 && (
           <ContentSection title={"Price"} desc={product?.price} />
           )}
          {product.aval && (
           <ContentSection title={"Availability"} desc={product.aval} />
           )}
          {product.detail && (
           <ContentSection title={"Detail"} desc={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.detail)}}/>} />
           )}
      
    </Box>
  );
};

export default ProductView;