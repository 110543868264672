import React, { useEffect, useState } from "react";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, useDisclosure,useToast, 
  Flex,
  HStack,
  Heading
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { deleteContact, deleteSubscriber, fetchAppointments,fetchSubscribers,downloadContact, downloadSubscriber} from "../../api";
import { useSelector } from "react-redux";

function ContactPage() {
  const [contacts, setContacts] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('contact');
  const [formData, setFormData] = useState({
    name: "",
  });
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchContactsb = async () => {
    try {
      const response = await fetchAppointments();
      setContacts(response);
      setData(response)
      const responseb = await fetchSubscribers();
      setSubscribers(responseb);
      
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const downloadContacts = async () => {
    try {
      const response = await downloadContact();
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const downloadSubscribers = async () => {
    try {
      const response = await downloadSubscriber();
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };
  // Fetch all categories
  useEffect(() => {
    fetchContactsb();
  },[]);
  
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const { user } = useSelector((state) => state.auth);
  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };
  const handleCategoryFilter = (category) => {
    setFilter(category);    
    if(category==="subscribers"){
    setData(subscribers);
  }else{
    setData(contacts);

    }
};

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Delete project
  const deleteContactb = async (id) => {
    try {
      await deleteContact(id);
      fetchContactsb();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };
  const deleteSubscriberb = async (id) => {
    try {
      await deleteSubscriber(id);
      fetchContactsb();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/add-contact"}>Save Contact</Button>
      <Button ml={2} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/contacts/add-sociallink"}>Add Social Links</Button>
      <Button ml={2} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/contacts/add-address"}>Add Address</Button>
      <Button ml={2} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/contacts/add-certificates"}>Add Certificates</Button>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" ml={2} onClick={() => {filter ==="contact"? downloadContacts():downloadSubscribers()}}>{filter ==="contact"? "Download BookingList":"Download Subscribers"}</Button>
  <Heading textAlign={"left"} as={"h5"} size={"lg"} mt={4}  color={"red"}>Record will delete after 60 days</Heading>
      <HStack
  spacing={{ base: '16px', md: '24px', lg: '32px' }}
  wrap="wrap" // Ensure proper wrapping on small screens
  align="left"
  justify={{ base: 'center', md: 'flex-start' }} // Center on small screens, left-align on larger screens
  w="100%"
  p={{ base: '4', md: '6' }}
>
  <Button
      bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
      variant="solid"
      size="md"
      bg={filter === 'contacts' ? 'white' : 'rgb(0 70 113 / var(--tw-bg-opacity, 1))'}
      color={filter === 'contacts' ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
      borderRadius="full"
      boxShadow="md"
      onClick={() => handleCategoryFilter("contacts")}
      leftIcon={<i className="fas fa-sync-alt" />} // Replace with relevant icon
      _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
    >
      Bookings
    </Button>
  <Button
    bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
    variant="solid"
    size="md"
    borderRadius="full"
    bg={filter === 'subscribers' ? 'white' : 'rgb(0 70 113 / var(--tw-bg-opacity, 1))'}
    color={filter === 'subscribers' ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
    boxShadow="md"
    onClick={() => handleCategoryFilter("subscribers")}
    leftIcon={<i className="fas fa-box" />} // Replace with relevant icon
    _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
  >
    Subscribers
  </Button>
</HStack>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Email</Th>
            {filter ==="contacts" &&
            <Th>Date</Th>
          }
            <Th>Remark</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((contact,index) => (
            <Tr key={contact.id}>
              <Td>{index+1}</Td>
              <Td>{contact.email}</Td>
              {filter ==="contacts" &&
              <Td>{contact?.date?.split('T')[0]}</Td>
              }
              <Td>
        {(() => {
          const createdAt = new Date(contact.createdAt);
          const today = new Date();
          const diffTime = createdAt.getTime() + 90 * 24 * 60 * 60 * 1000 - today.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays > 0) {
            return `${diffDays} days left`;
          } else {
            return `Overdue by ${Math.abs(diffDays)} days`;
          }
        })()}
      </Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={filter === "contact" ? `/edit-contact/${contact.id}`:`/edit-subscriber/${contact.id}`}>Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => { filter === "contact" ? deleteContactb(contact.id) : deleteSubscriberb(contact.id)}}>Delete</Button>
                      )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </div>
  );
}

export default ContactPage;