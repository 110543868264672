import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import DOMPurify from 'dompurify';
import { editBlog, fetchBlogById, fetchBlogs } from "../../api";
import ContentSection from "../Common/ContentSection";

const BlogDetail = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetchBlogById(id);
        if (response) {
        setBlog(response);
        console.log(response);
        
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);  

 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Image src={blog?.imagePath} alt={blog?.alt_text} borderRadius="md" mb={4} />
      <Heading as="h1" mb={4}>
        {blog.name}
      </Heading>     
      
      <Text
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog?.desc) }} // Render HTML content
      />
          {blog?.url && (
           <ContentSection title={"Blog Url"} desc={blog?.url} />
           )}
          {blog.categoryId?.name && (
           <ContentSection title={"Category"} desc={blog.categoryId?.name} />
           )}
           
           {blog?.emails && (
           <ContentSection title={"Emails"} desc={blog?.emails} />
           )}
          {blog.description && (
              <HStack
              flexWrap="wrap" 
              spacing={2} 
              mb={{ base: 2, md: 0 }} 
              align={{ base: "center", md: "start" }}
            >
              <Text fontWeight="bold" mb={{ base: 0, md: 2 }}>
                Description: 
              </Text>
              <Text
                dangerouslySetInnerHTML={{ __html: blog.description }} // Render HTML content
              />
            </HStack>
           )}
          {blog.excerpt && (
           <ContentSection title={"Excerpt"} desc={blog.excerpt} />
           )}
          {blog.focus_keys && (
           <ContentSection title={"Focus Keywords"} desc={blog.focus_keys} />
           )}
          {blog.alt_text && (
           <ContentSection title={"Alt Text"} desc={blog.alt_text} />
           )}
          {blog.caption_img && (
           <ContentSection title={"Caption Image"} desc={blog.caption_img} />
           )}
      {blog?.can_url && (
           <ContentSection title={"Canonical URL"} desc={blog?.can_url} />
           )}
           
           {blog?.soc_tags && (
           <ContentSection title={"Tags"} desc={blog?.soc_tags} />
           )}
           {blog?.schedule_time && (
           <ContentSection title={"Schedule Time"} desc={Date(blog.schedule_time)} />
           )}
           {blog?.meta_title && (
           <ContentSection title={"Meta Title"} desc={blog.meta_title} />
           )}
           {blog?.meta_desc && (
           <ContentSection title={"Meta Description"} desc={blog.meta_desc} />
           )}
           {blog?.meta_desc && (
           <HStack wrap="wrap" mb={4}>
      <Text fontWeight="bold" mb={2}>
      Status:
      </Text>
            <Tag bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="lg">
              {blog?.status}
            </Tag>
      </HStack>
      )}
    </Box>
  );
};

export default BlogDetail;