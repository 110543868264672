import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormControl, FormLabel, Button, Box, Image, HStack, useToast } from "@chakra-ui/react";

const MyImageUploader = ({ onImagesSelect }) => {
  const [images, setImages] = useState([]); // Store all selected images
  const [mainImage, setMainImage] = useState(null); // Store the selected main image
  const toast=useToast();
  // Handle dropzone files
  const onDrop = (acceptedFiles) => {
    const invalidFiles = acceptedFiles.filter(file => file.size > 2 * 1024 * 1024);

    if (invalidFiles.length > 0) {
      return toast({
        title: "An error occurred.",
        description: "File size should not exceed 2MB.",
        status: "error", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });     
    } 
    setImages(acceptedFiles); // Store selected images
  };

  // Set an image as the main image
  const handleSetMainImage = (image) => {
    setMainImage(image);
  };

  // Call the parent component's callback when the images are selected
  const handleSubmit = () => {
    onImagesSelect(images, mainImage); // Pass selected images and main image to the parent
  };

  // Render preview images
  const renderImagePreviews = () => {
    return images.map((image, index) => (
      <Box key={index} position="relative" mb={4}>
        <Image
          src={URL.createObjectURL(image)}
          alt={`Preview ${index}`}
          boxSize="150px"
          objectFit="cover"
          borderRadius="md"
        />
        <Button
          onClick={() => handleSetMainImage(image)}
          position="absolute"
          top="5px"
          left="5px"
          colorScheme={mainImage === image ? "green" : "blue"}
          size="sm"
        >
          {mainImage === image ? "Main" : "Set Main"}
        </Button>
      </Box>
    ));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  return (
    <form>
      <FormControl isRequired mb={4}>
      <HStack justifyContent="space-between">
                <FormLabel>Images</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Max Each File Size 2MB)
                </Box>
                </HStack>
        <div {...getRootProps()} style={{ border: "2px dashed #ccc", padding: "20px", textAlign: "center" }}>
          <input {...getInputProps()} />
          <p>Drag & drop images here, or click to select files</p>
        </div>
      </FormControl>

      {/* Display image previews and allow selecting the main image */}
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        {renderImagePreviews()}
      </Box>

      {/* Display the selected main image */}
      {mainImage && (
        <Box display="flex" flexDirection={"column"} alignItems="center" mt={4}>
          <FormLabel>Main Image</FormLabel>
          <Image
            src={URL.createObjectURL(mainImage)}
            alt="Main Image"
            boxSize="300px"
            objectFit="cover"
            borderRadius="md"
          />
        </Box>
      )}

      {/* Submit Button */}
      <Button mt={4} colorScheme="blue" onClick={handleSubmit}>
        Add Image
      </Button>
    </form>
  );
};

export default MyImageUploader;
