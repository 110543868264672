import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchBlogCategoriesById, editBlogCategory} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditCat = () => {
    const navigate=useNavigate();
    const toast=useToast();   
    const { id } = useParams();  
    const location = useLocation();
    const [formData, setFormData] = useState({
        name: "",    
      });  
      useEffect(() => {
        fetchCat();
      }, []);
      useEffect(() => {
          // Check if the current path is "/edit-blog/:id"
          if (location.pathname.startsWith("/edit-blogcategory/")) {
            // Prevent the user from going back to the previous page
            window.history.pushState(null, null, window.location.href);  // Add a new history entry
            window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
      
            // Handle the back/forward navigation event
            const handlePopState = () => {
              navigate("/blog-categories");  // Redirect to /blogs page if back is triggered
            };
      
            // Set the popstate event handler
            window.addEventListener("popstate", handlePopState);
      
            // Cleanup the event listener when the component unmounts or the path changes
            return () => {
              window.removeEventListener("popstate", handlePopState);
            };
          }
      
          // Cleanup for other routes (this does nothing for non-edit pages)
          return () => {};
            }, [navigate, location.pathname]);
      const fetchCat=async()=>{
        const res=await fetchBlogCategoriesById(id);
        setFormData({name:res.name})
        
      }
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        // const isFormInvalid = Object.values(formData).some(value => !value);
        const requiredFields = ["name"];
        const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `All fields are required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
        try {
            await editBlogCategory(id,formData);
            navigate("/blog-categories")
            toast({
              title: 'Category Updated Successfully.',
              description: "The category has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          
          setFormData({ name: ""});
    
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error adding the category.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>UPDATE CATEGORY</Heading>
     <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Category
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditCat;