// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';


// const PrivateRoute = ({ children }) => {
//     const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

//   if (!isAuthenticated) {
//     return <Navigate to="/login" />;
//   }

//   return children; // if authenticated, render the child components (e.g., ProjectPage)
// };

// export default PrivateRoute;
import React from "react";
import { Navigate,matchPath } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, requiredModule }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  if (!isLoggedIn || !user || !user.roles) {
    return <div>Loading...</div>; // Show a loading state
  }

  if (!user.modules || !Array.isArray(user.modules)) {
    return <Navigate to="/unauthorized" />;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  if (
    (requiredModule === "add-user" && !user.roles.includes("superadmin")) ||
    (requiredModule === "assign-role" && !user.roles.includes("superadmin")) ||
    (requiredModule === "roles" && !user.roles.includes("superadmin")) ||
    (requiredModule === "modules" && !user.roles.includes("superadmin"))
  ) {
    return <Navigate to="/unauthorized" />;
  }

  
  if(!user.roles.includes("admin") && !user.roles.includes("superadmin")){ 
    const hasAccess = user.modules.includes(requiredModule);
  if (!hasAccess) {
    return <Navigate to="/unauthorized" />;
  }
}
  return children;
};

export default PrivateRoute;