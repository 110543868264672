import React from 'react';
import { HStack, Text, VStack } from "@chakra-ui/react";

function ContentSection({title,desc}){
    return(
    <VStack
            align="start" 
            spacing={2} 
            mb={4} 
            width="100%" 
            display={{ base: "flex", md: "block" }}
          >
            <HStack
              flexWrap="wrap" 
              spacing={2} 
              mb={{ base: 2, md: 0 }} 
              align={{ base: "center", md: "start" }}
            >
              <Text fontWeight="bold" mb={{ base: 0, md: 2 }}>
              {title}:
            </Text>
              <Text>
                {desc}
              </Text>
            </HStack>
          </VStack>
      );
    }

export default ContentSection;