import { Box, Heading, Text, Button, VStack } from "@chakra-ui/react";

const JobApplicantList = ({ applicants }) => {
  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Heading as="h1" mb={4}>
        Job Applicants
      </Heading>
      <VStack spacing={4}>
        {applicants?.length > 0 ? (
            <>
        {applicants?.map((applicant) => (
          <Box
            key={applicant.id}
            p={4}
            shadow="md"
            borderWidth="1px"
            borderRadius="md"
            w="100%"
          >
            <Text fontWeight="bold" fontSize="lg" mb={2}>
              {applicant.name}
            </Text>
            <Text>Email: {applicant.email}</Text>
            <Text>Phone: {applicant.phone}</Text>
            <Button
              mt={2}
              colorScheme="blue"
              as="a"
              href={applicant.resume}
              download
            >
              View Resume
            </Button>
          </Box>
        ))}
        </>
        ):(
            <Heading as="h6" mt={4}>
                No Applicants Found
                </Heading>
        )}
      </VStack>
    </Box>
  );
};

export default JobApplicantList;