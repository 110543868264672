// CertificateTable.js
import React, { useEffect, useState } from 'react';
import { Table, Tbody, Tr, Td, Th, Thead, Button, useToast, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { fetchCertificates,deleteCertificate } from "../../api";
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';

const CertificateTable = () => {
  const [certificates, setCertificates] = useState([]);
  const toast=useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  useEffect(() => {
    fetchCertificatesb();
  }, []);
  const { user } = useSelector((state) => state.auth);
  const totalPages = Math.ceil(certificates.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = certificates.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  
const fetchCertificatesb = async () => {
      try {
        const data = await fetchCertificates();
        setCertificates(data);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      }
    };
  const handleDelete = async (id) => {
    try {
      await deleteCertificate(id);
      toast({
        title: "Action Successful!",
        description: "Certificate Successfully Deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCertificatesb();
    } catch (error) {
      return toast({
        title: "Error",
        description: "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });     
    }
  };

  return (
    <>
    <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/add-certificate" mr={4} >Add Certificate</Button>

      <Table>
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            {/* <Th>Image</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((certificate) => (
            <Tr key={certificate.id}>
              <Td>{certificate.sr_no}</Td>
              <Td>{certificate.name}</Td>
              {/* <Td>
                <img src={`${certificate.imagePath}`} alt={certificate.name} width="50" />
              </Td> */}
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} mr={4} as={RouterLink} to={`/edit-certificate/${certificate.id}`}> Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button onClick={() => handleDelete(certificate.id)} colorScheme="red">
                  Delete
                </Button>
                )}
                <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/certificate/${certificate.id}`}>View</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}

          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </>
  );
};

export default CertificateTable;