import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Select,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchJobs,createJob,editJob,deleteJob, fetchDepartmentById, fetchDepartments} from "../../api";
import { useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const AddJob = () => {
    const [responsb, setResponsb] = useState("");
    const [requir, setRequir] = useState("");
    const [skills, setSkills] = useState([]);
    const [departs, setDeparts] = useState([]);
    const navigate=useNavigate();
    const toast=useToast();
    const [formData, setFormData] = useState({
        title:"",
        email:"",
        location:"",
        desc:"",  
        deadline:"",
        status:"unpublish",  
        designation:"",
        department:"",
        type:""
      });
      const fetchDepartb = async () => {
        try {
          const responseb = await fetchDepartments();
          setDeparts(responseb);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      useEffect(() => {
        fetchDepartb();
      }, []);
      const handleResponsbChange = (selectedOptions) => {    
        setResponsb(selectedOptions);
      };
      const handleRequirChange = (selectedOptions) => {    
        setRequir(selectedOptions);
      };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        if(formData.name =='' || formData.email =='' || formData.designation ==''){
          return toast({
            title: "An error occurred.",
            description: "Please fill in all the required fields.",
            status: "error", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });     
        }
        const formDataToSend = new FormData();
        formDataToSend.append("title", formData.title);
        formDataToSend.append("desc", formData.desc);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("designation", formData.designation);
        formDataToSend.append("department", formData.department);
        formDataToSend.append("location", formData.location);
        formDataToSend.append("type", formData.type);
        formDataToSend.append("deadline", formData.appd);
        formDataToSend.append("status", formData.status);
        // formDataToSend.append("deadline", formData.deadline);
      //   if(formData.image !==''){
      //   formDataToSend.append("image", formData.image);  // Only append if image is uploaded
      // }
      skills.forEach(skill => {
        formDataToSend.append('skills[]', skill.value);
      });
        formDataToSend.append('responsb', responsb);
        formDataToSend.append('requir', requir);
        try {
    
        const response= await createJob(formDataToSend);
          
          setFormData({ title: "" });
          setResponsb([]);
          setRequir([]);
          navigate("/jobs");
          toast({
            title: "Action Successful!",
            description: "Form Successfully Submitted",
            status: "success", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });
        } catch (error) {
          toast({
            title: "Unexpected Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
          });
        
      }
      };
      const handleSkillsChange = (content) => {
        setSkills(content);      
      };
      
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
        <HStack justifyContent="space-between">
    <FormLabel isRequired>Email</FormLabel>
    <Box as="span" fontSize="sm" color="gray.500">
      (Send email Info@bacr.com.pk)
    </Box>
  </HStack>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Department</FormLabel>
          <Select name="department" value={formData.department} onChange={handleChange} placeholder="Select Department">
              {departs.map((depart) => (
                <option value={depart.id}>{depart.name}</option>
              ))}
              </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Designation</FormLabel>
          <Input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Responsibilities</FormLabel>
          {/* <CreatableSelect
            isMulti
            value={responsb}
            onChange={handleResponsbChange}
            options={[]}
            placeholder="Add or enter responsibilities"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          /> */}
           <ReactQuill
            theme="snow"
              maxLength={250}
              value={responsb}
              onChange={handleResponsbChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  [{ align: [] }],
                  ["clean"], // Remove formatting
                ],
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />
        </FormControl>

        <FormControl>
          <FormLabel>Requirements</FormLabel>
          {/* <CreatableSelect
            isMulti
            value={requir}
            onChange={handleRequirChange}
            options={[]}
            placeholder="Add or enter requirements"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          /> */}
          <ReactQuill
            theme="snow"
              maxLength={250}
              value={requir}
              onChange={handleRequirChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  ["clean"], // Remove formatting
                ],
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />
        </FormControl>
        <FormControl>
          <FormLabel>Skills</FormLabel>
          <CreatableSelect
            isMulti
            value={skills}
            onChange={handleSkillsChange}
            options={[]}
            placeholder="Add or enter skills"
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel >Type</FormLabel>
          <Select name="type" isRequired value={formData.type} onChange={handleChange} placeholder="Select Type">
                      <option value="parttime">Part Time</option>
                      <option value="fulltime">Full Time</option>                      
                    </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Location</FormLabel>
          <Textarea
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Application Deadline</FormLabel>
          <Input
            type="date"
            name="appd"
            value={formData.appd}
            onChange={handleChange}
          />
        </FormControl>

        <HStack justifyContent="space-between">
          <FormLabel>Status</FormLabel>
          <Switch
            name="status"
            isChecked={formData.status === "publish"}
            onChange={(e) =>
              handleChange({
                target: {
                  name: "status",
                  value: e.target.checked ? "publish" : "unpublish",
                },
              })
            }
          />
          <FormLabel>
          <Box
    as="mark"
    bg={formData.status === "publish" ? "blue.100" : "red.100"}
    color={formData.status === "publish" ? "blue.700" : "red.700"}
    px={2}
    py={1}
    borderRadius="md"
  >
    {formData.status === "publish" ? "Published" : "Unpublished"}
  </Box>
          </FormLabel>
        </HStack>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Job
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddJob