import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {fetchSocialLinks,createSocialLink} from "../../api";
import { useNavigate, useParams } from "react-router-dom";

const AddAddress = () => {
    const navigate=useNavigate();
    const toast=useToast();
    const [formData, setFormData] = useState({
        address1:"",
        address1head:"",
        address2head:"",
        address3head:"",
        address4head:"",
        address2:"",
        address3:"",
        address4:"",
      });

      useEffect(() => {
        fetchProdBrand();
      }, []);
      const fetchProdBrand=async()=>{
        const res=await fetchSocialLinks();
        setFormData({
        address1:res[0].address1,
        address1head:res[0].address1head,
        address2head:res[0].address2head,
        address3head:res[0].address3head,
        address4head:res[0].address4head,
        address1url:res[0].address1url,
        address2:res[0].address2,
        address2url:res[0].address2url,
        address3:res[0].address3,
        address3url:res[0].address3url,
        address4:res[0].address4,
        address4url:res[0].address4url,
          });
      }

    const handleAddSubmit = async (e) => {
        e.preventDefault();    
        try {
        const formDatab = new FormData();
        formDatab.append('address1', formData.address1);
        formDatab.append('address1head', formData.address1head);
        formDatab.append('address2head', formData.address2head);
        formDatab.append('address3head', formData.address3head);
        formDatab.append('address4head', formData.address4head);
        formDatab.append('address1url', formData.address1url);
        formDatab.append('address2', formData.address2);
        formDatab.append('address2url', formData.address2url);
        formDatab.append('address3', formData.address3);
        formDatab.append('address3url', formData.address3url);
        formDatab.append('address4', formData.address4);
        formDatab.append('address4url', formData.address4url);
        const response= await createSocialLink(formDatab);
        navigate("/contacts");
        toast({
        title: "Action Successful!",
        description: "Form Successfully Submitted",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
        });
    } catch (error) {
        toast({
        title: "Unexpected Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 5000, // Duration in milliseconds
        isClosable: true,
        });
    
    }
      };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]:value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg">
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl>
          <FormLabel>Address 1 Heading</FormLabel>
          <Input
            type="text"
            name="address1head"
            value={formData.address1head}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 1</FormLabel>
          <Textarea
            type="text"
            name="address1"
            value={formData.address1}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 1 Url</FormLabel>
          <Input
            type="text"
            name="address1url"
            value={formData.address1url}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 2 Heading</FormLabel>
          <Input
            type="text"
            name="address2head"
            value={formData.address2head}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 2</FormLabel>
          <Textarea
            type="text"
            name="address2"
            value={formData.address2}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 2 Url</FormLabel>
          <Input
            type="text"
            name="address2url"
            value={formData.address2url}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 3 Heading</FormLabel>
          <Input
            type="text"
            name="address3head"
            value={formData.address3head}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 3</FormLabel>
          <Textarea
            type="text"
            name="address3"
            value={formData.address3}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 3 Url</FormLabel>
          <Input
            type="text"
            name="address3url"
            value={formData.address3url}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 4 Heading</FormLabel>
          <Input
            type="text"
            name="address4head"
            value={formData.address4head}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 4</FormLabel>
          <Textarea
            type="text"
            name="address4"
            value={formData.address4}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Address 4 Url</FormLabel>
          <Input
            type="text"
            name="address4url"
            value={formData.address4url}
            onChange={handleChange}
          />
        </FormControl>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Address
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddAddress;