import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, Select, useDisclosure,useToast, 
  Flex
} from "@chakra-ui/react";
import { deleteDepartment,createDepartment,editDepartment,fetchDepartments,fetchDepartmentById} from "../../api";
import { useSelector } from "react-redux";

function DepartmentPage() {
  const [departments, setDepartments] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email:""
  });
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchDepartmentsb = async () => {
    try {
      const response = await fetchDepartments();
      setDepartments(response);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  // Fetch all department
  useEffect(() => {
    fetchDepartmentsb();
  },[]);
  
  const totalPages = Math.ceil(departments.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = departments.slice(indexOfFirstItem, indexOfLastItem);
  const { user } = useSelector((state) => state.auth);
  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle project update submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ["name"];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
  if (emptyFields.length > 0) {
    const emptyFieldsMessage = `All fields are required.`;
    toast({
      title: 'Error',
      description: emptyFieldsMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  
    return;
  }
    try {
      if (selectedDepartment) {
        const id=selectedDepartment.id;
        
        await editDepartment(formData,id);
        fetchDepartmentsb();
        toast({
          title: 'Department Updated Successfully.',
          description: "The department has been updated successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
      } else {
        await createDepartment(formData);
      fetchDepartmentsb();

        toast({
          title: 'Department Added Successfully.',
          description: "The department has been added successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
      setFormData({ name: ""});
      setSelectedDepartment(null);
    } catch (error) {
      if(selectedDepartment){
      toast({
        title: 'Error',
        description: "There was an error updating the department.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }else{
      toast({
        title: 'Error',
        description: "There was an error adding the department.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    }
  };

  const openEditModal = (department) => {
    setSelectedDepartment(department);
    
    setFormData({ ...department });
    onOpen();
  };

  const deleteDepartmentb = async (id) => {
    try {
      await deleteDepartment(id);
      fetchDepartmentsb();
    } catch (error) {
      console.error("Error deleting department:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={() => { setSelectedDepartment(null); onOpen(); }}>Add Department</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((department,index) => (
            <Tr key={department.id}>
              <Td>{index+1}</Td>
              <Td>{department.name}</Td>
              <Td>{department.email}</Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" onClick={() => openEditModal(department)}>Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => deleteDepartmentb(department.id)}>Delete</Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      {/* Update department Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedDepartment ? "Update Department" : "Add Department"}</ModalHeader>
          <ModalBody>
            <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input name="email" value={formData.email} onChange={handleChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={handleSubmit}>
              {selectedDepartment ? "Update" : "Add"} Department
            </Button>
            <Button onClick={onClose} ml={3}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DepartmentPage;