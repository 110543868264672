import React, { useEffect, useRef, useState } from "react";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, useDisclosure,useToast, 
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { fetchBlogCategories,createBlogCategory,deleteBlogCategory,editBlogCategory} from "../../api";
import { useSelector } from "react-redux";

function CategoryTable() {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
  });
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const cancelRef = useRef();    
  const openDeleteDialog = (projectId) => {
    setBlogToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setBlogToDelete(null);
    setIsDeleteDialogOpen(false);
  };
  const fetchCategoriesb = async () => {
    try {
      const response = await fetchBlogCategories();
      setCategories(response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  // Fetch all categories
  useEffect(() => {
    fetchCategoriesb();
  },[]);
  
  const totalPages = Math.ceil(categories.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };


  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle project update submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isFormInvalid = Object.values(formData).some(value => !value);
    const requiredFields = ["name"];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
  if (emptyFields.length > 0) {
    // Join the empty field names into a readable string
    const emptyFieldsMessage = `All fields are required.`;
    toast({
      title: 'Error',
      description: emptyFieldsMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  
    return; // Stop further execution
  }
    try {
      if (selectedCategory) {
        await editBlogCategory(selectedCategory.id,formData);
        fetchCategoriesb();
        toast({
          title: 'Category Updated Successfully.',
          description: "The category has been updated successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
      } else {
        await createBlogCategory(formData);
      fetchCategoriesb();

        toast({
          title: 'Category Added Successfully.',
          description: "The category has been added successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
      setFormData({ name: ""});
      setSelectedCategory(null);
    } catch (error) {
      if(selectedCategory){
      toast({
        title: 'Error',
        description: "There was an error updating the category.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }else{
      toast({
        title: 'Error',
        description: "There was an error adding the category.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    }
  };

  // Delete project
  const deleteCategoryb = async () => {
    try {
      if (!blogToDelete) return;
      await deleteBlogCategory(blogToDelete);
      fetchCategoriesb();
      closeDeleteDialog();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={"/add-blogcategory"}>Add Blog Category</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((category,index) => (
            <Tr key={category.id}>
              <Td>{index+1}</Td>
              <Td>{category.name}</Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/edit-blogcategory/${category.id}`}>Edit</Button>
                {user.roles.includes("admin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={()=>openDeleteDialog(category.id)}>Delete</Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCategory ? "Update Blog Category" : "Add Blog Category"}</ModalHeader>
          <ModalBody>
            <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={handleSubmit}>
              {selectedCategory ? "Update" : "Add"} Category
            </Button>
            <Button onClick={onClose} ml={3}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Category
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this blog category? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteCategoryb} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default CategoryTable;