import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { fetchTagById, editTag} from "../../api";
import { useNavigate, useParams } from "react-router-dom";

const EditTag = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const {id}=useParams();    
    const [formData, setFormData] = useState({
        name: "",
      });  

      const fetchTag = async () => {
        try {
          const response = await fetchTagById(id);
          console.log(response);
          
          setFormData({name:response.name});
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      // Fetch all categories
      useEffect(() => {
        fetchTag();
      },[]);
          
      const handleSubmit = async (e) => {
        e.preventDefault();
        // const isFormInvalid = Object.values(formData).some(value => !value);
        const requiredFields = ["name"];
        const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `Name field is required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return; // Stop further execution
      }
        try {
         
            await editTag(id,formData);
            navigate("/tags");
            toast({
              title: 'Tag Updated Successfully.',
              description: "The tag has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error adding the tag.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        
      };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            if (files.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>EDIT TAG</Heading>
     <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Tag
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditTag;