import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
    Select,
  } from "@chakra-ui/react";
  import CreatableSelect from "react-select/creatable";
import { createBlog, createCertificate, editBlog, fetchBlogCategories, fetchBlogs, fetchCertificateSrno, fetchTags, getAllBlogsUrl} from "../../api";
import { useNavigate } from "react-router-dom";
import ReactQuill,{Quill} from "react-quill";
import { MultiSelect } from "react-multi-select-component";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const AddBlog = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const [selectedTags, setSelectedTags] = useState([]);
    const [blogsUrl, setBlogsUrl] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [tags, setTags] = useState([]);
    const [emails, setEmails] = useState([]);
    const [socTags, setsocTags] = useState([]);
    const [focusKeys, setfocusKeys] = useState([]);
    const [categories, setCategories] = useState([]);
    
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        description:"",
        image:"",
        meta_desc:"",
        meta_title:"",
        caption_img:"",
        alt_text:"",
        can_url:"",
        excerpt:"",
        status:"",
        schedule_time:""
      });
      const handleTagChange = (selectedOptions) => {    
        setSelectedTags(selectedOptions);
      };
      const handleEmailChange = (selectedOptions) => {
        if (!selectedOptions) return;
      
        // Extract email strings if selectedOptions contains objects
        const emailArray = Array.isArray(selectedOptions)
          ? selectedOptions.map((option) => (typeof option === "object" ? option.value : option))
          : selectedOptions.split(",").map((email) => email.trim());
      
        // Validate all emails
        const invalidEmails = emailArray.filter((email) => !validateEmail(email));
      
        if (invalidEmails.length > 0) {
          console.error(`Invalid emails: ${invalidEmails.join(", ")}`);
          alert(`Invalid emails: ${invalidEmails.join(", ")}`);
          return; // Exit if there are invalid emails
        }
      
        // Set valid emails
        setSelectedEmails(selectedOptions);
      };
      const fetchBlogsb = async () => {
        try {
          const url =  await getAllBlogsUrl();
          setBlogsUrl(url);
          
          const responseb = await fetchBlogCategories();
          setCategories(responseb);
          const responsec = await fetchTags();
          setTags(responsec);
          
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      };
      // console.log(tags[0].name);
      // Fetch all blogs
      useEffect(() => {
        fetchBlogsb();
      },[]);
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          description: content,
        }));
      };
      const generateSlug = async (title) => {
       
        return title
          .toLowerCase()
          .replace(/\s+/g, '-')         // Replace spaces with hyphens
          .replace(/[^\w\-]+/g, '')     // Remove all non-word characters (except hyphens)
          .replace(/\-\-+/g, '-')       // Replace multiple hyphens with a single one
          .replace(/^-+/, '')           // Remove hyphen at the start of the string
          .replace(/-+$/, '');          // Remove hyphen at the end of the string
      };
      // Function to generate unique URL
      const generateUniqueUrl = async (title,blogsUrl) => {
        let uniqueUrl = await generateSlug(title);

        let suffix = 1;
        // Check if the generated URL already exists
        while (blogsUrl.some(blog => blog.url === uniqueUrl)) {
          uniqueUrl = `${uniqueUrl}-${suffix}`;
          suffix++;
        }        
        
        return uniqueUrl; // Return the unique URL
      };
    
      const processFormDataUrl = (url) => {
        const prefix = '/blog-detail/';
        const parts = url.split(prefix);
        return parts.length > 1 ? parts[1] : null;
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
      if (formData.name==="" || formData.description==="") {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `Name and Content, fields are required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
      const slug = processFormDataUrl(formData.url);

        try {
          const formDatab = new FormData();
          formDatab.append('name', formData.name);
          formDatab.append('url', slug);
          formDatab.append('description', formData.description);
          formDatab.append('image', formData.image);
          // if(emails) formDatab.append('email', formData?.emails ? formData?.emails : "");
          formDatab.append('categoryId', formData.categoryId);
          formDatab.append('can_url', formData.can_url);
          formDatab.append('alt_text', formData.alt_text);
          formDatab.append('caption_img', formData.caption_img);
          formDatab.append('meta_title', formData.meta_title);
          formDatab.append('meta_desc', formData.meta_desc);
          formDatab.append('status', formData.status);
          formDatab.append('excerpt', formData.excerpt);
          if(formData.status !== "schedule" ){
            formDatab.append('schedule_time', "");
          }else{
            formDatab.append('schedule_time', formData.schedule_time);
          }
          // emails.forEach(email => {
          //   formDatab.append('emails[]', email.value);
          // });

          focusKeys.forEach(focusKey => {
            formDatab.append('focus_keys[]', focusKey.value);
          });
          selectedEmails.forEach(selectedEmail => {
            formDatab.append('emails[]', selectedEmail.value);
          });
          socTags.forEach(socTag => {
            formDatab.append('soc_tags[]', socTag.value);
          });
          selectedTags.forEach(tagId => {
            formDatab.append('tags[]', tagId.value);
          });
          if (selectedBlog) {
            const id=selectedBlog.id;
            await editBlog(id,formDatab);
            fetchBlogsb();
            toast({
              title: 'Blog Updated Successfully.',
              description: "The blog has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            
          } else {
            await createBlog(formDatab);
            navigate("/blogs")
    
            toast({
              title: 'Blog Added Successfully.',
              description: "The blog has been added successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          }
          setFormData({ name: ""});
          setSelectedBlog(null);
        } catch (error) {
          if (error.status === 500) { 
           return toast({
              title: 'Error',
              description: "File size exceeds 5MB limit.",
              status: 'error',
              duration: 5000,
              isClosable: true,
            });  
           }

          if(selectedBlog){
          toast({
            title: 'Error',
            description: "There was an error updating the blog.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }else{
          toast({
            title: 'Error',
            description: "There was an error adding the blog.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        }
      };
      const tagOptions = tags.map(tag => ({
        value: tag.id,
        label: tag.name
      }));
    const handleChange = async(e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
        if (name==="name" ) {
          const uniqueUrl = await generateUniqueUrl(value, blogsUrl);

            setFormData((prevData) => ({
              ...prevData,
              url: "https://bacr.com.pk/blog-detail/"+uniqueUrl, // Auto-generate the URL slug
            }
          ));
           
          }
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <Heading textAlign={"center"}>ADD BLOG</Heading>
        <FormControl mt={4} isRequired>
              <FormLabel>Title</FormLabel>
              <Input name="name" value={formData?.name} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>URL1 Slug</FormLabel>
            <Input  name="url" value={formData?.url} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mb={4}>
              <FormLabel>Category</FormLabel>
              <Select name="categoryId" value={formData.categoryId} onChange={handleChange} placeholder="Select Category">
              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Tags</FormLabel>  
               <MultiSelect
              options={tagOptions}
              value={selectedTags}
              onChange={handleTagChange}
              labelledBy="Select"
            />
          </FormControl>
              {/* Multi-Email Input */}
              <FormControl mt={4}>
              <HStack justifyContent="space-between">
                <FormLabel>Emails</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (File Type csv and xlsx)
                </Box>
                </HStack>
                <CreatableSelect
                isMulti
                value={selectedEmails}
                onChange={handleEmailChange}
                options={[]}
                placeholder="Add or enter emails"
                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
              />
            {/* <CreatableSelect
              isMulti
              value={focusKeys}
              onChange={(selectedOptions) => setfocusKeys(selectedOptions)}
              options={[]}
              placeholder="Add or enter focus keys"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            /> */}
        {/* <Input
          type="file"
          name="emails"
          onChange={(e) => setFormData({ ...formData, emails: e.target.files[0] })}
        /> */}
      </FormControl>
      {/* <HStack justifyContent="flex-start"> */}
      {/* Buttons for downloading Excel and PDF files */}
      {/* <Button mt={4} colorScheme="blue" onClick={downloadExcel}>
        Dummy Excel File
      </Button>

      <Button mt={4} colorScheme="green" onClick={downloadPdf}>
      Requirement file pdf
      </Button> */}
      {/* </HStack> */}
            <FormControl mt={4} isRequired>
              <FormLabel>Content</FormLabel>
              <ReactQuill
              theme="snow"
              name="description"
              value={formData?.description}
              onChange={handleDescriptionChange}
              placeholder="Write your content here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                  ["bold", "italic", "underline", "strike"], // Text styles
                  [{ list: "ordered" }, { list: "bullet" }], // Lists
                  ["link", "image", "video"], // Links, Images, Videos
                  [{ align: [] }],
                  ["clean"], // Remove formatting
                ],
                imageResize: {
                  modules: ['Resize', 'DisplaySize'],
                },
              }}
            />
            </FormControl>
            <FormControl mt={4} isRequired>
              <HStack justifyContent="space-between">
                <FormLabel isRequired>Feature Image</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Max File Size 2MB)
                </Box>
                </HStack>
              <Input type="file" name="image" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Alt Text</FormLabel>
            <Input
              type="text"
              name="alt_text"
              placeholder="Enter alt text for the image"
              value={formData?.alt_text || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Image Caption</FormLabel>
            <Textarea
              name="caption_img"
              placeholder="Enter a caption for the image"
              value={formData?.caption_img || ''}
              onChange={handleChange}
            />
          </FormControl>
            <FormControl mt={4}>
              <FormLabel>Meta Title</FormLabel>
              <Input name="meta_title" value={formData?.meta_title} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Meta Description</FormLabel>
              <Textarea name="meta_desc" value={formData?.meta_desc} onChange={handleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Focus Keywords</FormLabel>
              {/* <Input name="focus_key" value={focus_key} onChange={handleChange} /> */}
              <CreatableSelect
              isMulti
              value={focusKeys}
              onChange={(selectedOptions) => setfocusKeys(selectedOptions)}
              options={[]}
              placeholder="Add or enter focus keys"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Social Media Tags</FormLabel>
              <CreatableSelect
              isMulti
              value={socTags}
              onChange={(selectedOptions) => setsocTags(selectedOptions)}
              options={[]}
              placeholder="Add or enter social media tags"
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Canonical URL</FormLabel>
            <Input name="can_url" value={formData?.can_url} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mb={4}>
              <FormLabel>Status</FormLabel>
              <Select name="status" value={formData.status} onChange={handleChange} placeholder="Select Status">
                <option value="draft">Draft</option>
                <option value="publish">Publish Now</option>
                <option value="schedule">Schedule Post</option>
              </Select>
          </FormControl>
                {formData.status === "schedule" && (
                <FormControl isRequired mb={4}>
                  <FormLabel>Schedule Time</FormLabel>
                  <Input
                    type="datetime-local"
                    name="schedule_time"
                    value={formData.schedule_time ? formData.schedule_time.slice(0, 16) : ""} 
                    onChange={handleChange}
                  />
                </FormControl>
                )}
              <FormControl mt={4}>
              <FormLabel>Excerpt</FormLabel>
              <Textarea name="excerpt" value={formData?.excerpt} onChange={handleChange} />
              </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Blog
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default AddBlog;