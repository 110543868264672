import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Heading,
  Stack,
  useToast,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { AssignRoles, fetchRoles, fetchUsers } from "../../api";

const AssignRole = () => {
  const [users, setUsers] = useState([]);
  const navigate=useNavigate();
  const [roles, setRoles] = useState([]);
  const [rolename, setRoleName] = useState("");
  const {id}=useParams();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({ userId: id, roleId: "" });
  const toast = useToast();

  // Fetch users and roles
  const fetchData = async () => {
    try {
      const usersRes = await fetchUsers(); // Replace with actual users API
      const rolesRes = await fetchRoles();
      
      const mappedRoles = rolesRes.map((role) => ({
        value: role.id, // Assuming `_id` is the unique identifier for the role
        label: role.name, // The display name of the role
      }));
      setUsers(usersRes.users);
      setRoles(mappedRoles);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: "Error fetching data",
        description: "Unable to load users or roles. Please try again later.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  // Assign role
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // await axios.post("https://bacr2024-backend-production.up.railway.app/api/assign-role", formData);
      await AssignRoles({userId:formData.userId,roleId:rolename});
      toast({
        title: "Role Assigned",
        description: "The role has been successfully assigned to the user.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/users");
      setFormData({ userId: "", roleId: "" });
    } catch (error) {
      console.error("Error assigning role:", error);
      toast({
        title: "Error assigning role",
        description: "An error occurred while assigning the role.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box p={6} maxWidth="500px" mx="auto" borderWidth="1px" borderRadius="md" boxShadow="lg">
      <Heading as="h2" size="lg" textAlign="center" mb={6}>
        Assign Role to User
      </Heading>
      <Box as="form" onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Select User</FormLabel>
            <Select
              placeholder="Select User"
              value={formData.userId}
              onChange={(e) => setFormData({ ...formData, userId: e.target.value })}
            >
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel> Role</FormLabel>
            {/* <Input type="text" name="role" value={formData.roke} onChange={(e) => setFormData({ ...formData, roleId: e.target.value })} /> */}
            <CreatableSelect
  value={
    formData.roleId
      ? roles.find((role) => role.value === formData.roleId) || { value: formData.roleId, label: formData.roleId }
      : null
  }
  onChange={(selectedOption) => {
    setFormData({
      ...formData,
      roleId: selectedOption?.value || '',
    });
    setRoleName(selectedOption?.label)
  }}
  options={roles}
  placeholder="Select Role"
  formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
/>
            {/* <CreatableSelect
              placeholder="Select Role"
              value={formData.roleId}
              onChange={(e) => setFormData({ ...formData, roleId: e.target.value })}
            >
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </CreatableSelect> */}
          </FormControl>
          <Button type="submit" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} width="full">
            Assign Role
          </Button>
          {user.roles==="admin" &&
          <Button as={RouterLink} to="/add-user" type="submit" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} width="full">
            Add New User
          </Button>
}
        </Stack>
      </Box>
    </Box>
  );
};

export default AssignRole;