import React from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, HStack, Stack, Text } from "@chakra-ui/react"

const Jobs = () => {
    const navigate = useNavigate();

    const handleJob = () => {
        navigate('/jobs'); 
    }
    const handleJobDep = () => {
        navigate('/job-departments'); 
    }

  return (
    <HStack   spacing={{ base: '16px', md: '24px', lg: '32px' }} // Adjust spacing between boxes for different screen sizes
    direction={{ base: 'column', md: 'row' }} // Stack boxes vertically on small screens, horizontally on larger screens
    align="center"
    justify="flex-start"
    w="100%" // Full width
    p={{ base: '4', md: '6' }}>
        <Box
        p={4}
        bg="blue.100"
        borderRadius="md"
        w={{ base: '100%', md: '45%', lg: '30%' }} // Adjust width based on screen size
        h={{ base: 'auto', md: '150px', lg: '200px'  }}
        boxShadow="md"
        onClick={handleJob}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
       <Heading> Jobs</Heading>
       </Box>
        <Box
        p={4}
        bg="blue.100"
        borderRadius="md"
        w={{ base: '100%', md: '45%', lg: '30%' }} // Adjust width based on screen size
        h={{ base: 'auto', md: '150px', lg: '200px'  }}
        boxShadow="md"
        display="flex"
        onClick={handleJobDep}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
       <Heading> Job Departments</Heading>
       </Box>
    </HStack>
  )
}
export default Jobs;