import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import ProjectPage from './components/projects/ProjectPage';
import AddProject from './components/projects/AddProject';
import ProjectView from './components/projects/ProjectView';
import ClientPage from './components/clients/ClientPage';
import CertificatePage from './components/certificates/CertificateTable';
import AddCertificate from './components/certificates/AddCertificate';
import EditCertificate from './components/certificates/EditCertificate';
import CertificateView from './components/certificates/CertificateView';
import ProductPage from './components/product/ProductPage';
import AddProduct from './components/product/AddProduct';
import Product from './components/product/Product';
import Brands from './components/product/Brands';
import ProductView from './components/product/ProductView';
import EditProduct from './components/product/EditProduct';
import DepartmentPage from './components/department/DepartmentPage';
import JobPage from './components/jobs/JobPage';
import AddJob from './components/jobs/AddJob';
import Jobs from './components/jobs/Jobs';
import Blogs from './components/blogs/Blogs';
import JobView from './components/jobs/JobView';
import EditJob from './components/jobs/EditJob';
import Team from './components/team/Team';
import AddTeam from './components/team/AddTeam';
import EditTeam from './components/team/EditTeam';
import TeamView from './components/team/TeamView';
import ModuleManagement from './components/ModuleManagement/ModuleManagement';
import CategoryPage from './components/category/CategoryPage';
import BlogCatPage from './components/blogCat/BlogCatPage';
import AddCat from './components/blogCat/AddCat';
import EditCat from './components/blogCat/EditCat';
import BlogPage from './components/blogs/BlogPage';
import BlogView from './components/blogs/BlogView';
import EditBlog from './components/blogs/EditBlog';
import AddBlog from './components/blogs/AddBlog';
import AddContact from './components/contact/AddContact';
import EditSubscriber from './components/contact/EditSubscriber';
import AddSocial from './components/contact/AddSocial';
import AddAddress from './components/contact/AddAddress';
import AddCertificates from './components/contact/AddCertificates';
import EditContact from './components/contact/EditContact';
import ContactPage from './components/contact/ContactPage';
import CareerPage from './components/career/CareerPage';
import AddCareer from './components/career/AddCareer';
import TagPage from './components/tags/TagPage';
import ProdBrand from './components/ProdBrand/ProdBrand';
import AddProdBrand from './components/ProdBrand/AddProdBrand';
import EditProdBrand from './components/ProdBrand/EditProdBrand';
import EditTag from './components/tags/EditTag';
import UserPage from './components/users/UserPage';
import RoleManagement from './components/roleMangement/RoleManagement';
import AssignRole from './components/roleMangement/AssignRole';
import AddTag from './components/tags/AddTag';
import SignupForm from './components/auth/signup';
import LoginForm from './components/auth/login';
import PrivateRoute from './components/PrivateRoute';
import { useSelector } from 'react-redux';
import EditProject from './components/projects/EditProject';
import Unauthorized from './components/Unauthorized';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const {isLoggedIn} = useSelector((state) => state.auth);
  const isAuthenticatedAdmin = useSelector((state) => state?.user); 
  const { user } = useSelector((state) => state.auth);

  return (
    <Router>
      <DashboardLayout>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/projects" />:<LoginForm />}  />
          <Route path="/projects" element={<PrivateRoute requiredModule="projects"><ProjectPage /></PrivateRoute>} />
          <Route path="/add-project" element={<PrivateRoute requiredModule="projects"><AddProject /></PrivateRoute>} />
          <Route path="/edit-project/:id" element={<PrivateRoute requiredModule="projects"><EditProject /></PrivateRoute>} />
          <Route path="/project/:id" element={<PrivateRoute requiredModule="projects"><ProjectView /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute requiredModule="clients"><ClientPage /></PrivateRoute>} />
          <Route path="/certificates" element={<PrivateRoute requiredModule="certificates"><CertificatePage /></PrivateRoute>} />
          <Route path="/add-certificate" element={<PrivateRoute requiredModule="certificates"><AddCertificate /></PrivateRoute>} />
          <Route path="/edit-certificate/:id" element={<PrivateRoute requiredModule="certificates"><EditCertificate /></PrivateRoute>} />
          <Route path="/certificate/:id" element={<PrivateRoute requiredModule="certificates"><CertificateView /></PrivateRoute>} />
          <Route path="/product" element={<PrivateRoute requiredModule="product"><Product /></PrivateRoute>} />
          <Route path="/products" element={<PrivateRoute requiredModule="product" ><ProductPage /></PrivateRoute>} />
          <Route path="/add-product/:brandId/:filter" element={<PrivateRoute requiredModule="product"><AddProduct /></PrivateRoute>} />
          <Route path="/edit-product/:brandId/:id" element={<PrivateRoute requiredModule="product"><EditProduct /></PrivateRoute>} />
          <Route path="/product/:id" element={<PrivateRoute requiredModule="product"><ProductView /></PrivateRoute>} />
          <Route path="/product-brand/:filter" element={<PrivateRoute requiredModule="product"><Brands /></PrivateRoute>} />
          <Route path="/teams" element={<PrivateRoute requiredModule="teams"><Team /></PrivateRoute>} />
          <Route path="/add-team" element={<PrivateRoute requiredModule="teams"><AddTeam /></PrivateRoute>} />
          <Route path="/edit-team/:id" element={<PrivateRoute requiredModule="teams"><EditTeam /></PrivateRoute>} />
          <Route path="/team/:id" element={<PrivateRoute requiredModule="teams"><TeamView /></PrivateRoute>} />
          <Route path="/categories" element={<PrivateRoute requiredModule="product" ><CategoryPage /></PrivateRoute>} />
          {/* <Route path="/roles" element={user && user.roles === "admin" ? <Navigate to="/unauthorized" />:<PrivateRoute><RoleManagement /></PrivateRoute>} /> */}
          <Route path="/roles" element={<PrivateRoute requiredModule="roles"><RoleManagement /></PrivateRoute>} />
          <Route path="/add-blogcategory" element={<PrivateRoute requiredModule="blog"><AddCat /></PrivateRoute>} />
          <Route path="/edit-blogcategory/:id" element={<PrivateRoute requiredModule="blog"><EditCat /></PrivateRoute>} />
          <Route path="/blog-categories" element={<PrivateRoute requiredModule="blog"><BlogCatPage /></PrivateRoute>} />
          <Route path="/blogs" element={<PrivateRoute requiredModule="blog"><BlogPage /></PrivateRoute>} />
          <Route path="/blog" element={<PrivateRoute requiredModule="blog"><Blogs /></PrivateRoute>} />
          <Route path="/add-blog" element={<PrivateRoute requiredModule="blog"><AddBlog /></PrivateRoute>} />
          <Route path="/blog/:id" element={<PrivateRoute requiredModule="blog"><BlogView /></PrivateRoute>} />
          <Route path="/edit-blog/:id" element={<PrivateRoute requiredModule="blog"><EditBlog /></PrivateRoute>} />
          <Route path="/job-departments" element={<PrivateRoute requiredModule="job-departments"><DepartmentPage /></PrivateRoute>} />
          <Route path="/jobs" element={<PrivateRoute requiredModule="jobs"><JobPage /></PrivateRoute>} />
          <Route path="/add-job" element={<PrivateRoute requiredModule="jobs"><AddJob /></PrivateRoute>} />
          <Route path="/job" element={<PrivateRoute requiredModule="jobs" ><Jobs /></PrivateRoute>} />
          <Route path="/edit-job/:id" element={<PrivateRoute requiredModule="jobs"><EditJob /></PrivateRoute>} />
          <Route path="/job/:id" element={<PrivateRoute requiredModule="jobs"><JobView /></PrivateRoute>} />
          <Route path="/add-tag" element={<PrivateRoute requiredModule="blog"><AddTag /></PrivateRoute>} />
          <Route path="/edit-tag/:id" element={<PrivateRoute requiredModule="blog"><EditTag /></PrivateRoute>} />
          <Route path="/tags" element={<PrivateRoute requiredModule="blog"><TagPage /></PrivateRoute>} />
          <Route path="/brands" element={<PrivateRoute requiredModule="brands"><ProdBrand /></PrivateRoute>} />
          <Route path="/add-brand" element={<PrivateRoute requiredModule="brands"><AddProdBrand /></PrivateRoute>} />
          <Route path="/edit-brand/:id" element={<PrivateRoute requiredModule="brands"><EditProdBrand /></PrivateRoute>} />
          <Route path="/contacts" element={<PrivateRoute requiredModule="contacts"><ContactPage /></PrivateRoute>} />
          <Route path="/careers" element={<PrivateRoute requiredModule="careers"><CareerPage /></PrivateRoute>} />
          <Route path="/add-career" element={<PrivateRoute requiredModule="careers"><AddCareer /></PrivateRoute>} />
          <Route path="/modules" element={<PrivateRoute requiredModule="modules"><ModuleManagement /></PrivateRoute>} />
          <Route path="/assign-role/:id" element={<PrivateRoute requiredModule="assign-role"><AssignRole /></PrivateRoute>} />
          <Route path="/add-contact" element={<PrivateRoute requiredModule="contacts"><AddContact /></PrivateRoute>} />
          <Route path="/contacts/add-sociallink" element={<PrivateRoute requiredModule="contacts"><AddSocial /></PrivateRoute>} />
          <Route path="/contacts/add-address" element={<PrivateRoute requiredModule="contacts"><AddAddress /></PrivateRoute>} />
          <Route path="/contacts/add-certificates" element={<PrivateRoute requiredModule="contacts"><AddCertificates /></PrivateRoute>} />
          <Route path="/edit-contact/:id" element={<PrivateRoute requiredModule="contacts"><EditContact /></PrivateRoute>} />
          <Route path="/edit-subscriber/:id" element={<PrivateRoute requiredModule="contacts"><EditSubscriber /></PrivateRoute>} />
          <Route path="/add-user" element={<PrivateRoute requiredModule="add-user"> <SignupForm /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute requiredModule="users"> <UserPage /></PrivateRoute>} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/projects" />:<LoginForm />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </DashboardLayout>
    </Router>
  );
}

export default App;