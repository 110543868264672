import React, { useState, useEffect } from "react";
import {
    Box,Button,Input,FormControl,FormLabel,
    Textarea,
    VStack,
    Heading,
    Text,
    useToast,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { editSubscriber,fetchSubscriberById} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditSubscriber = () => {
    const navigate=useNavigate();
    const location = useLocation();
    const toast=useToast();    
    const {id}=useParams();    
    const [formData, setFormData] = useState({
        name: "",
        email:"",
        message:"",
        phone:""
      });  
      useEffect(() => {
        fetchContact();
      }, []);
      useEffect(() => {
              // Check if the current path is "/edit-blog/:id"
              if (location.pathname.startsWith("/edit-subscriber/")) {
                // Prevent the user from going back to the previous page
                window.history.pushState(null, null, window.location.href);  // Add a new history entry
                window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
          
                // Handle the back/forward navigation event
                const handlePopState = () => {
                  navigate("/contacts");  // Redirect to /blogs page if back is triggered
                };
          
                // Set the popstate event handler
                window.addEventListener("popstate", handlePopState);
          
                // Cleanup the event listener when the component unmounts or the path changes
                return () => {
                  window.removeEventListener("popstate", handlePopState);
                };
              }
          
              // Cleanup for other routes (this does nothing for non-edit pages)
              return () => {};
            }, [navigate, location.pathname]);
      const fetchContact=async()=>{
        const res=await fetchSubscriberById(id);
        setFormData({
            name:res.name,
            email:res.email,
            phone:res.phone,
            message:res.message
        }) 
      }

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ["name"];
        const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `Name fields is required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
        try {
            await editSubscriber(formData,id);    
            navigate("/contacts")
            toast({
              title: 'Subscriber Updated Successfully.',
              description: "The subscriber has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          
          setFormData({ name: ""});
    
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error updated the subscriber.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>EDIT SUBSCRIBER</Heading>
              <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input name="email" value={formData.email} onChange={handleChange} />
            </FormControl>
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Subscriber
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditSubscriber;