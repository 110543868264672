import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, Select, useDisclosure,useToast, 
  Textarea,
  Flex,
  Text,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";

import { Link as RouterLink} from 'react-router-dom';
import { fetchProjects,editProject,deleteProject,createProject } from "../../api";
import MyImageUploader from "../Common/MyImageUploader";
import { useSelector } from "react-redux";

function ProjectTable() {
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    client: "",
    description: "",
    equipmentDelivery: "",
    sr_no: "",
    type: "",
  });
  const toast = useToast();
  const [selectedProject, setSelectedProject] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [images, setImages] = useState([]); // Store selected images
  const [mainImage, setMainImage] = useState(null); // Store the main image
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const cancelRef = useRef();

  const fetchProjectsb = async () => {
    try {
      const response = await fetchProjects();
      setProjects(response);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  // Fetch all projects
  useEffect(() => {
    fetchProjectsb();
  },[]);

  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const openDeleteDialog = (projectId) => {
    setProjectToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setProjectToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);
  const { user } = useSelector((state) => state.auth);
  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Delete project
  const handleDelete = async (id) => {
    try {
      if (!projectToDelete) return;
      await deleteProject(projectToDelete);
      closeDeleteDialog();
      fetchProjectsb();
      toast({
        title: "Action Successful!",
        description: "Project Successfully Deleted",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: "There was an error updating the blog.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to={`/add-project`}>Add Project</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Client</Th>
            <Th>Category</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
        {currentItems.map((project, index) => (
            <Tr key={project.id}>
              <Td>{project.sr_no}</Td> {/* Update SR No for pagination */}
              <Td>{project.name}</Td>
              <Td>{project.client}</Td>
              <Td>{project.category === 'trunkyProject'
                ? 'Trunky Project'
                : project.category === 'sup&comm'
                ? 'Supply and Commissioning'
                : project.category === 'oprMaint'
                ? 'Operation Maintenance'
                : project.category === 'oversea'
                ? 'Oversea Project'
                : ''}</Td>
              <Td display={"flex"} >
                <Button
                  bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}}
                  size="md"
                  as={RouterLink}
                  to={`/edit-project/${project.id}`}
                >
                  Edit
                </Button>
                {user.roles.includes("superadmin") &&( 
                <Button
                  colorScheme="red"
                  size="md"
                  ml={2}
                  // onClick={() => handleDelete(project.id)}
                  onClick={() => openDeleteDialog(project.id)}
                >
                  Delete
                </Button>
                )}
                <Button ml="2" bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink}   to={`/project/${project.id}`}>View</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
       {/* Pagination Controls */}
       <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this project? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default ProjectTable;