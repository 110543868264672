import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
  Table, Thead, Tbody, Tr, Th, Td, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, 
  FormControl, FormLabel, Input, Select, useDisclosure,useToast, 
  Flex
} from "@chakra-ui/react";
import { Link as RouterLink} from 'react-router-dom';
import { fetchProdBrands,createCategory,deleteBrand,editCategory} from "../../api";
import { useSelector } from "react-redux";

function ProdBrand() {
  const [brands, setBrands] = useState([]);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 10;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isToggleOn, setIsToggleOn] = useState(false);
    const [isToggleOnb, setIsToggleOnb] = useState(false);
    const [isToggleOnc, setIsToggleOnc] = useState(false);
    const [formData, setFormData] = useState({
        name:"",
        spPrt:"",
        newProd:"",
        usedProd:""
      });
    const { user } = useSelector((state) => state.auth);
  const fetchProdBrandsb = async () => {
    try {
      const response = await fetchProdBrands();
      setBrands(response);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };
  // Fetch all categories
  useEffect(() => {
    fetchProdBrandsb();
  },[]);
  
  const totalPages = Math.ceil(brands.length / itemsPerPage);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = brands.slice(indexOfFirstItem, indexOfLastItem);

  // Handlers for pagination
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  // Generate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Delete project
  const deleteBrandb = async (id) => {
    try {
      await deleteBrand(id);
      fetchProdBrandsb();
    } catch (error) {
      console.error("Error deleting brand:", error);
    }
  };

  return (
    <div>
      <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} as={RouterLink} to="/add-brand">Add Brand</Button>
      <Table mt={4} variant="simple">
        <Thead>
          <Tr>
            <Th>SR No</Th>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((brand,index) => (
            <Tr key={brand.id}>
              <Td>{(currentPage - 1) * itemsPerPage + index + 1}</Td>
              <Td>{brand.name}</Td>
              <Td>
                <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} size="md" as={RouterLink} to={`/edit-brand/${brand.id}`}>Edit</Button>
                {user.roles.includes("superadmin") &&( 
                <Button colorScheme="red" size="md" ml={2} onClick={() => deleteBrandb(brand.id)}>Delete</Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" align="center" mt={4} gap={2}>
        <Button
          onClick={handlePrevious}
          colorScheme="gray"
          isDisabled={currentPage === 1}
        >
          &lt;
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            bg={currentPage === pageNumber ? 'rgb(0 70 113 / var(--tw-bg-opacity, 1))' : 'white'}
            color={currentPage === pageNumber ? 'white' : 'black'}
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNext}
          colorScheme="gray"
          isDisabled={currentPage === totalPages}
        >
           &gt;
        </Button>
      </Flex>
    </div>
  );
}

export default ProdBrand;