import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Select,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { createProject, fetchProjectsSrno} from "../../api";
import { useNavigate } from "react-router-dom";
import MyImageUploader from "../Common/MyImageUploader";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const AddProject = () => {
    const navigate=useNavigate();
    const toast=useToast();
    const [formData, setFormData] = useState({
      name: "",
      location: "",
      client: "",
      description: "",
      equipmentDelivery: "",
      sr_no: "",
      type: "",
      category: "",
    });
      const [images, setImages] = useState([]); // Store selected images
      const [mainImage, setMainImage] = useState(""); // Store the main image
      const [sno, setSno] = useState(""); // Store the main image
      const [logo, setLogo] = useState(null); // Store the main image
      // Callback function to receive images and mainImage from MyImageUploader
      const handleImagesSelect = (selectedImages, selectedMainImage) => {
        setImages(selectedImages);
        setMainImage(selectedMainImage);
      };
      const fetchSrno = async () => {
        try {
          const response = await fetchProjectsSrno();
          setSno(response?.sr_no ? response?.sr_no :null);
          setFormData((prevData) => ({
            ...prevData,
            sr_no: response?.sr_no ? response?.sr_no + 1:null ,
          }));
        } catch (error) {
          console.error("Error fetching projects:", error);
        }
      };
      // Fetch all projects
      useEffect(() => {
        fetchSrno();
      },[]);
    const handleAddSubmit = async (e) => {
      e.preventDefault();
      const snob=sno+1;
      if(formData.sr_no > snob){
        toast({
          title: 'Error',
          description: "SR No cannot be greater than "+ snob,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return; 
      }
      // const isFormInvalid = Object.values(formData).some(value => !value);
      const requiredFields = ["name", "client", "location","description","sr_no"];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
    if (emptyFields.length > 0 || mainImage === "" ) {
      // Join the empty field names into a readable string
      const emptyFieldsMessage = `All fields are required`;
      toast({
        title: 'Error',
        description: emptyFieldsMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }
    const formDataToSend = new FormData();
    formDataToSend.append("mainImage", mainImage);
    images.forEach(image => formDataToSend.append("images", image));
    formDataToSend.append("client", formData.client);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("sr_no", formData.sr_no);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category", formData.category);
    if(logo instanceof File){
      formDataToSend.append("logo", logo);
      }
      try {
          // Add new project
          await createProject(formDataToSend);
          navigate("/projects");
          toast({
            title: "Action Successful!",
            description: "Project Successfully Added",
            status: "success", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });
      } catch (error) {
        console.error("Error submitting project:", error);
      }
      };
      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          description: content,
        }));
      };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
      const handleLogoChange = (e) => {
        const {files } = e.target;
        if (files) {
          const file = e.target.files[0];
          
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes
            
            
            if (file.size > maxSize) {
                e.target.value="";
              return toast({
                title: "An error occurred.",
                description: "File size should not exceed 2MB.",
                status: "error", // "error" for error message
                duration: 5000,  // Duration in milliseconds
                isClosable: true, // Option to close the toast
              });     
            }
          }
        setLogo(files[0]);
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        
      <FormControl isRequired>
              <HStack justifyContent="space-between">
                <FormLabel>SR No</FormLabel>
                <Box as="span" fontSize="sm" color="gray.500">
                (Last SR NO {sno})
                </Box>
                </HStack>
              <Input name="sr_no" value={formData.sr_no} onChange={handleChange} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Location</FormLabel>
              <Input name="location" value={formData.location} onChange={handleChange} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Client</FormLabel>
              <Input name="client" value={formData.client} onChange={handleChange} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Description</FormLabel>
              <ReactQuill
                theme="snow"
                  maxLength={250}
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  placeholder="Write your content here..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                      ["bold", "italic", "underline", "strike"], // Text styles
                      [{ list: "ordered" }, { list: "bullet" }], // Lists
                      ["link", "image", "video"], // Links, Images, Videos
                      ["clean"], // Remove formatting
                    ],
                    imageResize: {
                      modules: ['Resize', 'DisplaySize'],
                    },
                  }}
                />                 
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Category</FormLabel>
              <Select name="category" value={formData.category} onChange={handleChange}>
                <option value="">Select Category</option>
                <option value="trunkyProject">Trunky Project</option>
                <option value="sup&comm">Supply and Commissioning</option>
                <option value="oprMaint">Opertion Maintenance</option>
                <option value="oversea">Oversea Project</option>
              </Select>
            </FormControl>
               <FormControl mt={4} isRequired>
                          <FormLabel>Logo</FormLabel>
                          <Input name="logo" type="file" onChange={handleLogoChange} />
                        </FormControl>
            <FormControl isRequired mb={4}>
            <MyImageUploader onImagesSelect={handleImagesSelect} /> {/* Pass the callback function to MyImageUploader */}

          {/* Display the selected images and main image */}
          {images.length > 0 && (
            <Box mt={4}>
              <Text fontSize="lg" mb={2}>
                Selected Images:
              </Text>
              <Box display="flex" flexWrap="wrap" justifyContent={"space-evenly"}>
                {images.map((image, index) => (
                  <Box key={index} width="150px" mb={4}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected Image ${index}`}
                        width="100%"
                        style={{ objectFit: "cover", borderRadius: "8px" }}
                      />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}

          {mainImage && (
            <Box display="flex"  flexDirection={"column"} alignItems="center" mt={4}>
              <Text fontSize="lg" mb={2}>
                Main Image:
              </Text>
              <Box width="300px">
                <img
                  src={URL.createObjectURL(mainImage)}
                  alt="Main Image"
                  width="100%"
                  style={{ objectFit: "cover", borderRadius: "8px" }}
                />
              </Box>
            </Box>
          )}

              </FormControl>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add Project
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddProject;