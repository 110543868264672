import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {fetchSocialLinks,createSocialLink} from "../../api";
import { useNavigate, useParams } from "react-router-dom";

const AddSocial = () => {
    const navigate=useNavigate();
    const toast=useToast();
    const [formData, setFormData] = useState({
        whatsapp:"",
        facebook:"",
        instagram:"",
        linkedin:"",
        youtube:"",
      });

      useEffect(() => {
        fetchProdBrand();
      }, []);
      const fetchProdBrand=async()=>{
        const res=await fetchSocialLinks();
        setFormData({
        whatsapp:res[0]?.whatsapp,
        facebook:res[0]?.facebook,
        instagram:res[0]?.instagram,
        linkedin:res[0]?.linkedin,
        youtube:res[0]?.youtube,
          });
          
      }

    const handleAddSubmit = async (e) => {
        e.preventDefault();    
        try {
        const formDatab = new FormData();
        formDatab.append('whatsapp', formData.whatsapp);
        formDatab.append('facebook', formData.facebook);
        formDatab.append('instagram', formData.instagram);
        formDatab.append('linkedin', formData.linkedin);
        formDatab.append('youtube', formData.youtube);
        const response= await createSocialLink(formDatab);
        navigate("/contacts");
        toast({
        title: "Action Successful!",
        description: "Form Successfully Submitted",
        status: "success", // "error" for error message
        duration: 5000,  // Duration in milliseconds
        isClosable: true, // Option to close the toast
        });
    } catch (error) {
        toast({
        title: "Unexpected Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 5000, // Duration in milliseconds
        isClosable: true,
        });
    
    }
      };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg">
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl>
          <FormLabel>WhatsApp</FormLabel>
          <Input
            type="text"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Instagram</FormLabel>
          <Input
            type="text"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>LinkedIn</FormLabel>
          <Input
            type="text"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Youtube</FormLabel>
          <Input
            type="text"
            name="youtube"
            value={formData.youtube}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Facebook</FormLabel>
          <Input
            type="text"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
          />
        </FormControl>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Add SocialLinks
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default AddSocial;