import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { fetchCertificateById } from "../../api";
import ContentSection from "../Common/ContentSection";
import CertificateViewer from "./CertificateViewer";
import { PDFViewer } from '@react-pdf/renderer';


const CertificateView = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await fetchCertificateById(id);
        if (response) {
        setCertificate(response);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [id]);  
 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Image src={certificate?.imagePath} alt={certificate?.alt_text} borderRadius="md" mb={4} />
      {certificate?.imagePath &&
      <>
    {/* <Button bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} onClick={onOpen}>
    View Certificate
  </Button> */}

  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Certificate Viewer</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <div style={{ height: '100px', overflow: 'auto' }}>
          <CertificateViewer pdfFile={certificate?.imagePath} />
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
  </>
      }

      <Heading as="h1" mb={4}>
        {certificate?.name}
      </Heading>     
      
          {certificate?.sr_no && (
           <ContentSection title={"SR NO"} desc={certificate?.sr_no} />
           )}
          {certificate?.issuedBy && (
           <ContentSection title={"IssuedBy"} desc={certificate?.issuedBy} />
           )}
           {certificate?.issuedDate && (
           <ContentSection title={"Issued Date"} desc={Date(certificate?.issuedDate)} />
           )}
           {certificate?.expirationDate && (
           <ContentSection title={"Expiration Date"} desc={Date(certificate?.expirationDate)} />
           )}
      
    </Box>
  );
};

export default CertificateView;