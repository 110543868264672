import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Tag,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { fetchProjectById } from "../../api";
import DOMPurify from 'dompurify';
import ContentSection from "../Common/ContentSection";

const ProjectView = () => {
  const { id } = useParams(); // Get blog ID from the route
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetchProjectById(id);
        if (response) {
        setProject(response.project);
        
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);  

 
  if (loading) return <Spinner size="xl" mt={8} />;
  if (error)
    return (
      <Alert status="error" mt={8}>
        <AlertIcon />
        {error}
      </Alert>
    );

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Image src={project?.imagePath} alt={project?.alt_text} borderRadius="md" mb={4} />
      <Heading as="h1" mb={4}>
        {project?.name}
      </Heading>     
      
          {project?.sr_no && (
           <ContentSection title={"SR NO"} desc={project?.sr_no} />
           )}
          {project?.location && (
           <ContentSection title={"Location"} desc={project?.location} />
           )}
           
           {project?.emails && (
           <ContentSection title={"Emails"} desc={project?.emails} />
           )}
          {project.description && (
           <ContentSection title={"Description"} desc={ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project?.description) }}/>} />
           )}
          {project.client && (
           <ContentSection title={"Client"} desc={project.client} />
           )}
          {project.equipmentDelivery && (
           <ContentSection title={"Equipment Delivery"}   desc={project.equipmentDelivery === "oversea" ? "Oversea" : project.equipmentDelivery === "nationW" ? "Nation Wide" : "Unknown"}
           />
           )}
          {project.type && (
           <ContentSection title={"Type"}   desc={project.type === "oversea" ? "Oversea" : project.type === "nationW" ? "Nation Wide" : "Unknown"}
           />
           )}
          {project.alt_text && (
           <ContentSection title={"Alt Text"} desc={project.alt_text} />
           )}
          {project.caption_img && (
           <ContentSection title={"Caption Image"} desc={project.caption_img} />
           )}
      {project?.can_url && (
           <ContentSection title={"Canonical URL"} desc={project?.can_url} />
           )}
           
           {project?.soc_tags && (
           <ContentSection title={"Tags"} desc={project?.soc_tags} />
           )}
           {project?.schedule_time && (
           <ContentSection title={"Schedule Time"} desc={Date(project.schedule_time)} />
           )}
           {project?.meta_title && (
           <ContentSection title={"Meta Title"} desc={project.meta_title} />
           )}
           {project?.meta_desc && (
           <ContentSection title={"Meta Description"} desc={project.meta_desc} />
           )}

      
    </Box>
  );
};

export default ProjectView;