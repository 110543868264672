import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,useToast,
    Textarea,
    Switch,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import {fetchProdBrandById,editProdBrand} from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const ImageBlot = Quill.import('formats/image');
ImageBlot.className = 'ql-formats'; // Optional class for styling
Quill.register(ImageBlot)

const EditProdBrand = () => {
    const navigate=useNavigate();
    const toast=useToast();
    const {id}=useParams();
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [isToggleOnb, setIsToggleOnb] = useState(false);
    const [isToggleOnc, setIsToggleOnc] = useState(false);
    const [subBrand, setsubBrand] = useState(false);
    const [isToggleOnd, setIsToggleOnd] = useState(false);
    const location = useLocation();
    const [formData, setFormData] = useState({
        name:"",
        spPrt:"",
        website:"",
        newProd:"",
        details:"",
        usedProd:"",
        authorize:0,
        image:"",
        coverimage:"",
      });
      useEffect(() => {
        fetchProdBrand();
      }, []);
      useEffect(() => {
        // Check if the current path is "/edit-blog/:id"
        if (location.pathname.startsWith("/edit-brand/")) {
          // Prevent the user from going back to the previous page
          window.history.pushState(null, null, window.location.href);  // Add a new history entry
          window.history.replaceState(null, null, window.location.href); // Optionally replace the current history entry
    
          // Handle the back/forward navigation event
          const handlePopState = () => {
            navigate("/brands");  // Redirect to /blogs page if back is triggered
          };
    
          // Set the popstate event handler
          window.addEventListener("popstate", handlePopState);
    
          // Cleanup the event listener when the component unmounts or the path changes
          return () => {
            window.removeEventListener("popstate", handlePopState);
          };
        }
    
        // Cleanup for other routes (this does nothing for non-edit pages)
        return () => {};
      }, [navigate, location.pathname]);
      const fetchProdBrand=async()=>{
        const res=await fetchProdBrandById(id);
        setFormData({
            name:res.name,
            spPrt:res.spPrt,
            newProd:res.newProd,
            details:res.details,
            website:res.website,
            usedProd:res.usedProd,
            authorize:res.authorize
          });
          if(res.newProd==1){
            setIsToggleOn(true);    
          }
          if(res.usedProd==1){
            setIsToggleOnb(true);    
          }
          if(res.spPrt==1){
            setIsToggleOnc(true);    
          }
          if(res.authorize==1){
            setIsToggleOnd(true);    
          }
          if(res.category=="subbrand"){
            setsubBrand(true);    
          }
        
      }
      const handleToggleChangec = (e) => {
        setIsToggleOnc(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            spPrt: "1", // Set to "1" if toggle is ON
          });
        } else {
          setFormData({
            ...formData,
            spPrt: "", // Reset to empty when toggle is OFF
          });
        }
      };
      console.log("formData.authorize "+formData.authorize);
      
      const handleToggleChange = (e) => {
        setIsToggleOn(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            newProd: "1",
          });
        } else {
          setFormData({
            ...formData,
            newProd: "", 
          });
        }
      };
      const handleToggleChangeb = (e) => {
        setIsToggleOnb(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            usedProd: "1", 
          });
        } else {
          setFormData({
            ...formData,
            usedProd: "", 
          });
        }
      };
      const handleToggleChanged = (e) => {
        setIsToggleOnd(e.target.checked);
        if (e.target.checked) {
          setFormData({
            ...formData,
            authorize: 1, 
          });
        } else {
          setFormData({
            ...formData,
            authorize: 0, 
          });
        }
      };

      const handleDescriptionChange = (content) => {
        // Update description field when rich text editor changes
        setFormData((prevData) => ({
          ...prevData,
          details: content,
        }));
      };
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        if(formData.name ==''){
          return toast({
            title: "An error occurred.",
            description: "Name fields is required",
            status: "error", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });     
        }
        try {
          const formDatab = new FormData();
          formDatab.append('name', formData.name);
          if(formData.spPrt) formDatab.append('spPrt', formData.spPrt);
          if(formData.website) formDatab.append('website', formData.website);
          if(formData.details) formDatab.append('details', formData.details);
          if(subBrand) formDatab.append('category', "subbrand");
          if(!subBrand) formDatab.append('category', "brand");
          if(formData.image){
            formDatab.append('image', formData.image);
          }
          if(formData.coverimage){
          formDatab.append('coverimage', formData.coverimage);
          }
          if(formData.newProd) formDatab.append('newProd', formData.newProd);
          if(formData.usedProd) formDatab.append('usedProd', formData.usedProd);
          formDatab.append('authorize', formData.authorize);        
          const response= await editProdBrand(id,formDatab);
          navigate("/brands");
          toast({
            title: "Action Successful!",
            description: "Form Successfully Updated",
            status: "success", // "error" for error message
            duration: 5000,  // Duration in milliseconds
            isClosable: true, // Option to close the toast
          });
        } catch (error) {
          toast({
            title: "Unexpected Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000, // Duration in milliseconds
            isClosable: true,
          });
        
      }
      };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: files ? files[0] : value,
        }));
      };
  return (
    <Box
    w={{ base: "100%", md: "80%", lg: "60%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
    <form onSubmit={handleAddSubmit}>
      <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel htmlFor="show-price" mb="0">
          Sub Brand
        </FormLabel>
        <Switch
          id="show-price"
          isChecked={subBrand}
          onChange={() => setsubBrand((prev) => !prev)}
        />
      </FormControl>
      {!subBrand && (
          <>
        <FormControl>
          <FormLabel>Website</FormLabel>
          <Input
            type="text"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Details</FormLabel>
          <ReactQuill
                theme="snow"
                  maxLength={250}
                  value={formData.details}
                  onChange={handleDescriptionChange}
                  placeholder="Write your content here..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3,4,5,6, false] }], // Headings H1-H3
                      ["bold", "italic", "underline", "strike"], // Text styles
                      [{ list: "ordered" }, { list: "bullet" }], // Lists
                      ["link", "image", "video"], // Links, Images, Videos
                      [{ align: [] }],
                      ["clean"], // Remove formatting
                    ],
                    imageResize: {
                      modules: ['Resize', 'DisplaySize'],
                    },
                  }}
                />                 
        </FormControl>
        <FormControl>
          <FormLabel>Image</FormLabel>
          <Input
            type="file"
            name="image"
            accept="image/jpeg, image/png , image/jpg"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Cover Image</FormLabel>
          <Input
            type="file"
            name="coverimage"
            accept="image/jpeg, image/png , image/jpg"
            onChange={handleChange}
          />
        </FormControl>
        </>
        )}
        <HStack spacing={4} align="stretch" justifyContent={"center"}>
        <FormControl >
          <FormLabel>New</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOn}
          onChange={handleToggleChange}
          mr={3}
        />
      </Box>
        </FormControl>
        <FormControl >
          <FormLabel>Used</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOnb}
          onChange={handleToggleChangeb}
          mr={3}
        />
      </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Spare Parts</FormLabel>
          <Box display="flex" alignItems="center" mb={2}>
        <Switch
          id="designation-toggle"
          isChecked={isToggleOnc}
          onChange={handleToggleChangec}
          mr={3}
        />
      </Box>
        </FormControl>
        </HStack>  
        <HStack justifyContent="space-between">
        <FormLabel>Authorize</FormLabel>
        <Switch
          name="status"
          isChecked={isToggleOnd}
          onChange={handleToggleChanged}
        />
        <FormLabel>
        <Box
        as="mark"
        bg={isToggleOnd ? "blue.100" : "red.100"}
        color={isToggleOnd ? "blue.700" : "red.700"}
        px={2}
        py={1}
        borderRadius="md"
      >
        {isToggleOnd  ? "Authorize" : "Unauthorize"}
      </Box>
        </FormLabel>
        </HStack>
        <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Brand
                </Button>
                </VStack>
      </VStack>
    </form>
  </Box>
  )
}

export default EditProdBrand
