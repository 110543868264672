import React, { useState, useEffect } from "react";
import {
    Box,Table,Thead,Tbody,Tr,Th,Td,Button,Input,FormControl,FormLabel,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,ModalFooter,useDisclosure,useToast,Flex,
    Textarea,
    Switch,
    HStack,
    VStack,
    Heading,
    Text,
  } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import { editAppointment, editContact, fetchAppointmentById, fetchContactById} from "../../api";
import { useNavigate, useParams } from "react-router-dom";

const EditContact = () => {
    const navigate=useNavigate();
    const toast=useToast();    
    const {id}=useParams();    
    const [formData, setFormData] = useState({
        name: "",
        email:"",
        message:"",
        phone:""
      });  
      useEffect(() => {
        fetchContact();
      }, []);
      const fetchContact=async()=>{
        const res=await fetchAppointmentById(id);
        setFormData({
            name:res.name,
            email:res.email,
            date:res.date
        })
        
      }
    const today = new Date().toISOString().split('T')[0];
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        // const isFormInvalid = Object.values(formData).some(value => !value);
        const requiredFields = ["name"];
        const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        // Join the empty field names into a readable string
        const emptyFieldsMessage = `Name fields is required.`;
        toast({
          title: 'Error',
          description: emptyFieldsMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      
        return; // Stop further execution
      }
        try {
            await editAppointment(formData,id);    
            navigate("/contacts")
            toast({
              title: 'Appointment Updated Successfully.',
              description: "The appointments has been updated successfully.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          
          setFormData({ name: ""});
    
        } catch (error) {
          toast({
            title: 'Error',
            description: "There was an error updated the contact.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        
      };
  return (
    <Box
    w={{ base: "100%", md: "40%", lg: "40%" }} // Responsive width
    mx="auto" // Center align horizontally
    p={4} // Padding
    bg="gray.50" // Background color
    borderRadius="md"
    boxShadow="lg"
  >
     <form onSubmit={handleAddSubmit}>
     <VStack spacing={4} align="stretch" justifyContent={"flex-start"}>
     <Heading textAlign={"center"}>EDIT CONTACT</Heading>
     <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formData.name} onChange={handleChange} />
            </FormControl>
              <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input name="email" value={formData.email} onChange={handleChange} />
            </FormControl>
              <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input name="date" type="date" value={formData.date}  min={today} onChange={handleChange} />
            </FormControl>
            {/* <FormControl mt={4}>
              <FormLabel>Phone</FormLabel>
              <Input name="phone" value={formData.phone} onChange={handleChange} />
            </FormControl>
     <FormControl mt={4}>
              <FormLabel>Message</FormLabel>
              <Textarea name="message" value={formData.message} onChange={handleChange} />
            </FormControl> */}
              <VStack justifyContent="flex-start">
        <Button size={"lg"} bg="rgb(0 70 113 / var(--tw-bg-opacity, 1))" color={"white"} _hover={{color:"white",bg:"rgb(0 70 113 / var(--tw-bg-opacity, 1))"}} type="submit" alignSelf="flex-end">
                  Update Contact
                </Button>
                </VStack>
      </VStack>
            </form>
  </Box>
  )
}

export default EditContact;